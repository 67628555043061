var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "@agentx/agentx-mfe-wc-based";
import "@uuip/unified-ui-platform";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import { UTILS_DYNAMIC } from "../utils/UtilsDynamic";
import style from "./PageTitle.scss";
/**
 * @element page-title
 */
let PageTitle = class PageTitle extends LitElement {
    constructor() {
        super(...arguments);
        this.pageTitle = "";
        this.titleText = "";
        this.titleHasRegex = (title) => {
            const extractedString = title.substring(title.indexOf("{") + 1, title.indexOf("}")).trim()[0];
            const substring = extractedString === "$";
            return title.includes("{") && title.includes("}", title.indexOf("{")) && substring;
        };
        this.extractString = (regexString) => {
            let extractedString = "";
            const s = regexString.split("{");
            s.forEach(str => {
                const regexEndIndex = str.indexOf("}");
                if (regexEndIndex !== -1) {
                    str = str.trim();
                    let extractedRegex = str.substring(0, regexEndIndex);
                    const upd = (v) => (extractedRegex = v);
                    const { value } = UTILS_DYNAMIC.storeDataProvider(extractedRegex, upd);
                    extractedString =
                        regexEndIndex === str.length
                            ? extractedString + value
                            : extractedString + value + str.substring(regexEndIndex + 1);
                }
                else {
                    extractedString = extractedString + str;
                }
            });
            return extractedString;
        };
        this.extractPageTitle = (title) => {
            if (this.titleHasRegex(title)) {
                return this.extractString(title);
            }
            else {
                const upd = (v) => (title = v);
                const { value } = UTILS_DYNAMIC.storeDataProvider(title, upd);
                return value;
            }
        };
    }
    static get styles() {
        return style;
    }
    connectedCallback() {
        super.connectedCallback();
        this._timerInterval = setInterval(() => {
            this.titleText = this.extractPageTitle(this.pageTitle);
        }, 1000);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        clearInterval(this._timerInterval);
    }
    render() {
        return html `
      <md-label class="page-title-label"><div class="page-title">${this.titleText}</div></md-label>
    `;
    }
};
__decorate([
    property()
], PageTitle.prototype, "pageTitle", void 0);
__decorate([
    internalProperty()
], PageTitle.prototype, "titleText", void 0);
__decorate([
    internalProperty()
], PageTitle.prototype, "_timerInterval", void 0);
PageTitle = __decorate([
    customElement("page-title")
], PageTitle);
export { PageTitle };
