var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AGENT_STATE_CHANGEDBY_NAME, SYSTEM_ROLES } from "@/app/components/constants";
import { stripSpecialChar } from "@/app/utils/Utils";
import { registerWebCalling } from "@/app/utils/UtilsWebCalling";
import { t } from "@/mixins/i18nMixin";
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import AGENTX_SW_CONTROLLER from "@/sw/sw-controller";
import { SERVICE, formRoleNameForTelemetry, getTrackingIdFromErrorObject } from "@agentx/agentx-services";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import Cookies from "js-cookie";
import { action, computed, observable } from "mobx";
import { hideBanner } from "../app/utils/AlertBannerUtils";
import { UserRoles } from "../app/utils/DesktopLayoutUtils";
import defaultLogo from "../assets/images/webex_cc_logo.png";
const TASK_AREA_COOKIES = "taskAreaCollapsed";
const AUX_PANEL_COOKIES = "auxilliaryPanelCollapsed";
const DARK_MODE_KEY = "darkMode";
const WAS_ASKED_PWA_INSTALL_COOKIES = "askedPwaInstall";
const IPAD_LANDSCAPE_LOWER_LIMIT = 1024;
const USER_PREFERENCE = "user_preference";
const logger = createLogger("[store-app]");
export class ModuleApp {
    constructor() {
        var _a, _b;
        this.title = "Webex Contact Center";
        this.logo = defaultLogo;
        this.appTitleAsImage = false;
        this.taskAreaCollapsed = false;
        this.auxilliaryPanelCollapsed = false;
        this.defaultMaximizeAreaName = "app-maximize-area";
        this.darkThemeEnabled = true;
        this.darkMode = false;
        this.wasAskedPwaInstall = false;
        this.showAuxPanelAsOverlay = true;
        this.changeTeamLoading = false;
        this.isStationLoginOpen = false;
        this.isEmergencyModalOpen = false;
        this.changeTeamsError = false;
        this.isStationLoginSuccess = false;
        this.changeProfileSettingsLoading = false;
        this.changeProfileSettingsError = false;
        this.stopNavigateOnAcceptTask = true;
        this.collapseVoiceIntPane = false; //configured in json to control expand/collapse of voice interaction CAD section
        this.isVoiceIntPaneUpdatedByUser = null;
        this.taskPageIllustration = "";
        this.isRTDIntialized = false;
        this.microsoftOrgConfig = null;
        this.webexOrgConfig = null;
        this.datacenter = "";
        /**
         * WXM
         */
        this.wxmEnabled = false;
        this.userModel = {
            ".expires": undefined,
            ".issued": undefined,
            access_token: "",
            email: "",
            expires_in: 0,
            hash: "",
            managedBy: "",
            primaryRole: "",
            station: "",
            token_type: "bearer",
            userName: "",
            isCIToken: true
        };
        /**
         * WEBEX
         */
        this.webexEnabled = false;
        this.webexShowModal = localStorage.getItem("webex-show-modal") === "true" || false;
        this.webexMinimized = localStorage.getItem("webex-minimize-modal") === "true" || false;
        this.language = localStorage.getItem("lng") || "";
        this.webexMeetingTimeStamp = Number.parseInt(localStorage.getItem((_b = (_a = SERVICE.constants) === null || _a === void 0 ? void 0 : _a.WebexConstants) === null || _b === void 0 ? void 0 : _b.WEBEX_MEETING_TIME_STAMP) || "") || 0;
        this.webexDndEnabled = false;
        this.webexEventRegistered = false;
        this.isEnvironmentProd = false;
        this.domainUrl = "";
        this.featureFlags = {
            isCareAttachmentEnabled: true,
            isVideoEnabled: false,
            isCloudCherryEnabled: false,
            isDigitalFFEnabled: true,
            isPCIComplianceEnabled: true,
            isDynamicDesktopLayoutEnable: false,
            isDesktopReasonCodeEnabled: false,
            isImiChatWidgetEnabled: false,
            isSteeringDigitsAppendEnabled: false,
            isTahoeEnabled: false,
            isSupervisorDesktopEnabled: false,
            isESDStationLoginFlowEnabled: false,
            isSecureCADVariablesEnabled: false,
            isCMSConfigMigrated: false,
            isOutdialSpecialCharsEnabled: false,
            isDesktopNotesWidgetEnabled: false,
            isAdvancedHeaderEnabled: false,
            isLogRocketEnabled: false,
            isDesktopContactCleanup: false,
            isLogRocketMaskingDisabled: false,
            isInternationalDiallingEnabled: false,
            isInvitationPopoverEnabled: false,
            isConfigurableTaskVariablesEnabled: false,
            isIMIGlobalVariablesEnabled: false,
            isGlobalVariablesEnabled: false,
            isDefaultLandingpageEnabled: false,
            isDesktopConsultToEntryPointDnEnabled: false,
            isMidCallMonitoringEnabled: false,
            isMonitoringHoldUnHoldEnabled: false,
            isDesktopMaxSessionLimitsEnabled: false,
            isAIDFixedVariablesEnabled: false,
            isStateChangeBySupervisorEnabled: false,
            isNetworkCheckPingAPIEnabled: false,
            isCallbackRetryEnabled: false,
            isWebRTCEnabled: false,
            isProgressiveCampaignEnabled: false,
            isProgressiveCampaign1NEnabled: false,
            isDesktopMaerskEnhancementEnabled: false,
            isESDPostInteractionInsightsEnabled: false,
            isDesktopMaerskAcqueonTeamId: false,
            isDesktopBargeInEnabled: false,
            isNativeChat: false,
            isDesktopCpdViewEnabled: false,
            isAddressBookInfiniteScrollEnabled: false,
            isProjectionOutdialANIEnabled: false,
            isDesktopMSStateSyncEnabled: false,
            isDesktopAuxCodesInfiniteScrollEnabled: false,
            isDesktopWebexStateSyncEnabled: false,
            isDesktopEmergencyNotificationEnabled: false,
            isWebrtcRingingEnabled: false,
            isWebrtcEndCallEnabled: false,
            isWxccMultiPartyConfEnabled: false,
            isQMWShowConsultRecordingsEnabled: false,
            isDesktopConferenceRecordingEnabled: false,
            isWxccPersistCallEnabled: false,
            isShowSignedOutAgentsEnabled: false,
            isWxccAgentInteractionEnabled: false,
            isMomentumV2Enabled: false,
            isWebexDirectoryViewEnabled: false,
            isWxccAllowOutdialCallAlwaysEnabled: false,
            isMaskSensitiveDataFFEnabled: false
        };
        this.pciCompliance = {
            action: "redact",
            isAttachmentEnabled: true
        };
        this.pciAllowedAttachmentTypes = "";
        this.imiScriptLoaded = false;
        this.getDeviceType = (isExtension) => {
            return isExtension ? SERVICE.constants.LoginVoiceOptions.EXTENSION : SERVICE.constants.LoginVoiceOptions.AGENT_DN;
        };
        this.fetchDefaultIdleCodeId = (orgId) => __awaiter(this, void 0, void 0, function* () {
            try {
                const params = { orgId };
                const response = yield SERVICE.abs.fetchDefaultAuxCodes(params);
                logger.info(`event=fetchDefaultAuxCodes : Successfully fetched ${response.length} default aux codes`);
                return response[0].id.toString();
            }
            catch (error) {
                logger.error(`event=fetchDefaultIdleCodes | Tracking ID: ${getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : ""} | Fetching default aux code is failed : ${error}`, error);
                throw error;
            }
        });
        this.getDefaultAuxCode = () => {
            if (this.featureFlags.isDesktopAuxCodesInfiniteScrollEnabled) {
                const response = this.fetchDefaultIdleCodeId(STORE.agent.orgId);
                if (response) {
                    return response.toString();
                }
            }
            else if (STORE.agent.idleCodes.length > 0) {
                const defaultStateOption = STORE.agent.idleCodes.filter(option => option.isDefault);
                if (defaultStateOption[0]) {
                    return defaultStateOption[0].id.toString();
                }
            }
            return "";
        };
        const _taskAreaCollapsed = Cookies.get(TASK_AREA_COOKIES);
        this.taskAreaCollapsed = _taskAreaCollapsed === "true";
        const _auxilliaryPanelCollapsed = Cookies.get(AUX_PANEL_COOKIES);
        this.auxilliaryPanelCollapsed = _auxilliaryPanelCollapsed === "true";
        const _showAuxPanelAsOverlay = true;
        this.showAuxPanelAsOverlay = _showAuxPanelAsOverlay;
        const _darkMode = localStorage.getItem(DARK_MODE_KEY);
        this.darkMode = _darkMode === "true";
        const _wasAskedPwaInstall = Cookies.get(WAS_ASKED_PWA_INSTALL_COOKIES);
        this.wasAskedPwaInstall = _wasAskedPwaInstall === "true";
        const _changeTeamLoading = false;
        this.changeTeamLoading = _changeTeamLoading;
        const _changeProfileSettingsLoading = false;
        this.changeProfileSettingsLoading = _changeProfileSettingsLoading;
    }
    /**
     * Computed values to simplify passing data to Web Components
     */
    get chatConfigs() {
        return {
            isCareAttachmentEnabled: this.featureFlags.isCareAttachmentEnabled,
            isVideoEnabled: this.featureFlags.isVideoEnabled,
            isDigitalFFEnabled: this.featureFlags.isDigitalFFEnabled,
            isPCIComplianceEnabled: this.featureFlags.isPCIComplianceEnabled
        };
    }
    get multipartyOrPersistsFF() {
        return this.featureFlags.isWxccMultiPartyConfEnabled || this.featureFlags.isWxccPersistCallEnabled;
    }
    get pciSettings() {
        return {
            action: this.pciCompliance.action,
            isAttachmentEnabled: this.pciCompliance.isAttachmentEnabled
        };
    }
    get pciAllowedAttachments() {
        return this.pciAllowedAttachmentTypes;
    }
    get emailConfigs() {
        return {
            isCareAttachmentEnabled: this.featureFlags.isCareAttachmentEnabled,
            isPCIComplianceEnabled: this.featureFlags.isPCIComplianceEnabled
        };
    }
    get imiConfigs() {
        return {
            imiWidgetEnabled: this.featureFlags.isImiChatWidgetEnabled,
            imiScriptLoaded: this.imiScriptLoaded,
            isStationLoginSuccess: this.isStationLoginSuccess
        };
    }
    get cssDynamicVars() {
        return {
            "--interaction-control-block-height": `${!STORE.agentContact.interactionControlDimension.consultCallContainerHeight
                ? STORE.agentContact.interactionControlDimension.mainCallContainerHeight
                : STORE.agentContact.interactionControlDimension.mainCallContainerHeight +
                    STORE.agentContact.interactionControlDimension.consultCallContainerHeight}px`
        };
    }
    updateIsRTDInitializedFlag(value) {
        this.isRTDIntialized = value;
    }
    login(stationLoginData, userPrefData) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const login = yield SERVICE.aqm.agent.stationLogin({
                    data: stationLoginData
                });
                let teamUniqueId = "";
                if (STORE.app.featureFlags.isDesktopMaerskAcqueonTeamId &&
                    stationLoginData.teamId &&
                    stationLoginData.teamId !== "") {
                    const team = yield SERVICE.cms.fetchAllTeams(STORE.agent.orgId, {
                        dbId: stationLoginData.teamId
                    });
                    if (Array.isArray(team) && team.length > 0) {
                        teamUniqueId = team[0].id;
                    }
                    else {
                        throw new Error(`Error fetching response for teamId: ${stationLoginData.teamId}`);
                    }
                }
                logger.info("event=stationLoginSuccess | Station login success after Profile Settings updation", login.data);
                // Remove state change banner on profile update
                hideBanner(AGENT_STATE_CHANGEDBY_NAME);
                const userLastSelectedRole = STORE.agent.userSelectedRole;
                STORE.agent.updateStatusAfterProfileUpdate();
                STORE.agent.updateSelectedAgentRole(((_a = stationLoginData === null || stationLoginData === void 0 ? void 0 : stationLoginData.roles) === null || _a === void 0 ? void 0 : _a.join("_")) || "");
                STORE.agent.updateDnNumber(stationLoginData.dialNumber || "");
                STORE.agent.updateUsesOtherDN(stationLoginData.usesOtherDN);
                STORE.agent.updateTeamId(stationLoginData.teamId || "");
                STORE.agent.updateTeamUniqueId(teamUniqueId || "");
                STORE.agent.updateTeamName(stationLoginData.teamName || "");
                STORE.agent.updateIsExtension(stationLoginData.isExtension || false);
                STORE.agent.updateExtensionNumber(userPrefData.extensionNumber);
                STORE.agent.updateDialNumberValueForNonUS(userPrefData.dialNumberValueForNonUS);
                STORE.agent.updateIsInternational(userPrefData.isInternational);
                STORE.agent.updateCountryCallingCode(userPrefData.countryCallingCode);
                STORE.agent.updateChannelCapacity(login.data.channelsMap);
                STORE.agent.updateInternationalPhoneNumber(userPrefData.isInternational ? userPrefData.internationalPhoneNumber : "");
                STORE.agent.updateCountryCodeValue(userPrefData.countryCodeValue);
                STORE.agent.updateIsRememberMyCredentials(userPrefData.isRememberMyCredentials);
                // Always update the store value for desktop notification on login
                if (STORE.app.featureFlags.isDesktopEmergencyNotificationEnabled) {
                    STORE.agent.updateEmergencyModalDisplay(userPrefData.isEmergencyModalAlreadyDisplayed);
                }
                if (STORE.app.featureFlags.isWebRTCEnabled) {
                    STORE.agent.updateDeviceType(stationLoginData.deviceType || this.getDeviceType(stationLoginData.isExtension || false));
                    if (STORE.agent.role === SYSTEM_ROLES.AGENT &&
                        stationLoginData.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
                        registerWebCalling(this.domainUrl);
                    }
                }
                if (!SERVICE.conf.profile) {
                    logger.error("event=NoConfigProfile | No Config profile after StationLogin while update profile");
                }
                else {
                    if (STORE.agent.isRememberMyCredentials) {
                        this.saveOrUpdateUserPreferences(Object.assign({ selectedRoleName: (_b = stationLoginData === null || stationLoginData === void 0 ? void 0 : stationLoginData.roles) === null || _b === void 0 ? void 0 : _b.join("_"), teamName: userPrefData.teamName, teamId: userPrefData.teamId, dialNumber: stationLoginData.dialNumber, isExtension: stationLoginData.isExtension, extensionNumber: userPrefData.extensionNumber, dialNumberValueForNonUS: userPrefData.dialNumberValueForNonUS, isInternational: userPrefData.isInternational, internationalPhoneNumber: userPrefData.internationalPhoneNumber, countryCallingCode: userPrefData.countryCallingCode, countryCodeValue: userPrefData.countryCodeValue, isRememberMyCredentials: userPrefData.isRememberMyCredentials, deviceType: stationLoginData.deviceType, deviceId: stationLoginData.deviceId }, (STORE.app.featureFlags.isDesktopEmergencyNotificationEnabled && {
                            isEmergencyModalAlreadyDisplayed: userPrefData.isEmergencyModalAlreadyDisplayed
                        })));
                        this.saveUserPreferenceInSessionStorage(userPrefData);
                    }
                    else {
                        if (STORE.app.featureFlags.isDesktopEmergencyNotificationEnabled) {
                            this.saveOrUpdateUserPreferences({
                                isEmergencyModalAlreadyDisplayed: userPrefData.isEmergencyModalAlreadyDisplayed ? true : false
                            });
                            this.deleteUserPreferenceFromSessionStorage();
                        }
                        else {
                            this.deleteUserPreferences();
                            this.deleteUserPreferenceFromSessionStorage();
                        }
                    }
                    SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.CHANGE_PROFILE_SETTINGS, {
                        Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS,
                        ChangedProfileSettings: stationLoginData,
                        Reason: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
                    });
                    const switchedRole = (_c = stationLoginData === null || stationLoginData === void 0 ? void 0 : stationLoginData.roles) === null || _c === void 0 ? void 0 : _c.join("_");
                    // when user switches role from profile settings, call mixpanel event
                    if (userLastSelectedRole !== ((_d = stationLoginData === null || stationLoginData === void 0 ? void 0 : stationLoginData.roles) === null || _d === void 0 ? void 0 : _d.join("_"))) {
                        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SWITCH_USER_ROLE, {
                            status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS,
                            LastUserRole: userLastSelectedRole === UserRoles.SUPERVISOR_AGENT
                                ? formRoleNameForTelemetry(userLastSelectedRole)
                                : userLastSelectedRole,
                            SwitchedRole: switchedRole === UserRoles.SUPERVISOR_AGENT ? formRoleNameForTelemetry(switchedRole) : switchedRole,
                            Reason: "[Profile Settings] User switched Role"
                        });
                    }
                    const notificationData = {
                        type: Notifications.ItemMeta.Type.Info,
                        mode: Notifications.ItemMeta.Mode.AutoDismiss,
                        title: t("app:notifications.changeProfileNotifTitle"),
                        data: t("app:notifications.changeProfileNotifData")
                    };
                    STORE.generalNotifications.fireNotificationEvent(notificationData);
                }
            }
            catch (e) {
                this.setChangeProfileSettingsLoginFailed(true);
                this.updateStationLoginOpen(true);
                this.updateEmergencyModalOpen(false);
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.CHANGE_PROFILE_SETTINGS, {
                    Status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
                    ChangedProfileSettings: stationLoginData.teamName,
                    Reason: "Update Profile failed at stationLogin"
                });
                logger.error(`event=stationLoginFailed | Update Profile failed at stationLogin ${e}`, e);
            }
        });
    }
    loginAgentWithNewTeam(stationLoginData) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield SERVICE.aqm.agent.stationLogin({
                    data: stationLoginData
                });
                STORE.agent.updateTeamName(stationLoginData.teamName || "");
                if (!SERVICE.conf.profile) {
                    logger.error("event=NoConfigProfile | No Config profile after StationLogin while changing teams");
                }
                else {
                    SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.CHANGE_TEAM, {
                        Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS,
                        ChangedTeam: stationLoginData.teamName,
                        Reason: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
                    });
                    const notificationData = {
                        type: Notifications.ItemMeta.Type.Info,
                        mode: Notifications.ItemMeta.Mode.AutoDismiss,
                        title: t("app:notifications.changeTeamNotifTitle"),
                        data: t("app:notifications.changeTeamNotifData") + stationLoginData.teamName
                    };
                    STORE.generalNotifications.fireNotificationEvent(notificationData);
                }
            }
            catch (e) {
                this.setChangeTeamsLoginFailed(true);
                this.updateStationLoginOpen(true);
                this.updateEmergencyModalOpen(false);
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.CHANGE_TEAM, {
                    Status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
                    ChangedTeam: stationLoginData.teamName,
                    Reason: "Team change failed at stationLogin"
                });
                logger.error(`event=stationLoginFailed | Team change failed at stationLogin ${e}`, e);
            }
        });
    }
    getAgentDN() {
        return localStorage ? localStorage.getItem("agent-dn-number") : "";
    }
    saveUserPreferenceInSessionStorage(data) {
        sessionStorage.setItem(USER_PREFERENCE, JSON.stringify(data));
    }
    deleteUserPreferenceFromSessionStorage() {
        sessionStorage.removeItem(USER_PREFERENCE);
    }
    /**
     * Save or Update User Preferences when submit is called from User Station Login or Profile Settings
     * @param payloadUserPreferences
     */
    saveOrUpdateUserPreferences(payloadUserPreferences) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (STORE.agent.isPreferenceSaved) {
                    // If preferences already exists update the saved ones
                    const data = yield SERVICE.userPrefs.updateUserPreferences({
                        organizationId: STORE.agent.organizationId,
                        id: STORE.agent.meUserId,
                        data: payloadUserPreferences
                    });
                    this.saveUserPreferenceInSessionStorage(data);
                    logger.info("event=userPreferencesUpdateSuccess | updating User Preferences Success : ", payloadUserPreferences, `length=${JSON.stringify(payloadUserPreferences).length}`);
                    if ("isInternational" in payloadUserPreferences) {
                        STORE.agent.updateIsInternational(payloadUserPreferences.isInternational);
                    }
                    if ("isRememberMyCredentials" in payloadUserPreferences) {
                        STORE.agent.updateIsRememberMyCredentials(payloadUserPreferences.isRememberMyCredentials);
                    }
                    if ("countryCallingCode" in payloadUserPreferences) {
                        STORE.agent.updateCountryCallingCode(payloadUserPreferences.countryCallingCode);
                    }
                    if ("internationalPhoneNumber" in payloadUserPreferences) {
                        STORE.agent.updateInternationalPhoneNumber(payloadUserPreferences.internationalPhoneNumber);
                    }
                    if ("countryCodeValue" in payloadUserPreferences) {
                        STORE.agent.updateCountryCodeValue(payloadUserPreferences.countryCodeValue);
                    }
                    if ("deviceType" in payloadUserPreferences) {
                        STORE.agent.updateDeviceType(payloadUserPreferences.deviceType || null);
                    }
                    if ("isEmergencyModalAlreadyDisplayed" in payloadUserPreferences) {
                        STORE.agent.updateEmergencyModalDisplay(payloadUserPreferences.isEmergencyModalAlreadyDisplayed);
                    }
                }
                else {
                    //else save new preferences
                    const data = yield SERVICE.userPrefs.saveUserPreferences({
                        organizationId: STORE.agent.organizationId,
                        id: STORE.agent.meUserId,
                        data: payloadUserPreferences
                    });
                    this.saveUserPreferenceInSessionStorage(data);
                    if ("isEmergencyModalAlreadyDisplayed" in payloadUserPreferences) {
                        STORE.agent.updateEmergencyModalDisplay(payloadUserPreferences.isEmergencyModalAlreadyDisplayed);
                    }
                    logger.info("event=userPreferencesSaveSuccess | saving User Preferences Success : ", payloadUserPreferences, `length=${JSON.stringify(payloadUserPreferences).length}`);
                }
            }
            catch (error) {
                logger.error("event=userPreferencesSaveFailed | saving User Preferences Error : ", error);
                const notificationData = {
                    type: Notifications.ItemMeta.Type.Info,
                    mode: Notifications.ItemMeta.Mode.AutoDismiss,
                    title: "",
                    data: t("app:notifications.profileSavedFailedData")
                };
                STORE.generalNotifications.fireNotificationEvent(notificationData);
            }
        });
    }
    /**
     * Delete User Preferences when Remember Me checkbox is unchecked and submit is called from User Station Login or Profile Settings
     */
    deleteUserPreferences() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (STORE.agent.isPreferenceSaved) {
                    // If preferences exists, delete the saved ones
                    yield SERVICE.userPrefs.deleteUserPreferences({
                        organizationId: STORE.agent.organizationId,
                        id: STORE.agent.meUserId
                    });
                    logger.info("event=userPreferencesDeleteSuccess | deleting User Preferences Success");
                }
            }
            catch (error) {
                logger.error("event=userPreferencesDeleteFailed | deleting User Preferences Error : ", error);
            }
        });
    }
    /**
     * capture the event when user switched device type from Desktop to any other value
     * @param deviceType
     */
    addTelemetryOnDeviceSwitchFromDesktop(deviceType) {
        var _a, _b;
        if (STORE.agent.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER &&
            deviceType !== STORE.agent.deviceType) {
            SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.WEBRTC_VOICE_CHANNEL_SWITCHED, {
                deviceType
            }, [(_b = (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.SERVICE_PROVIDERS) === null || _b === void 0 ? void 0 : _b.mixpanel]);
        }
    }
    logout(profileDetails) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setChangeProfileSetingsLoading(true);
            logger.info("[UpdateProfile] Initiating Logout for Update Profile");
            SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.SIGN_OUT);
            const logoutResponse = SERVICE.aqm.agent.logout({
                data: { logoutReason: "User requested logout" }
            });
            const ifSupervisorOnly = profileDetails.roles.join("_") === "supervisor";
            logoutResponse
                .then(() => __awaiter(this, void 0, void 0, function* () {
                if (STORE.app.featureFlags.isWebRTCEnabled &&
                    STORE.agent.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
                    logger.info("[WebRtc] : deregistering webcalling");
                    yield SERVICE.webCalling.deregisterWebCalling(); // should wait for deregistration in case of profile switch.
                }
                if (profileDetails.deviceType) {
                    this.addTelemetryOnDeviceSwitchFromDesktop(profileDetails.deviceType);
                }
                const userStationLoginData = {
                    dialNumber: profileDetails.dnNumber || "",
                    usesOtherDN: profileDetails.usesOtherDN,
                    isExtension: profileDetails.isExtension,
                    teamId: ifSupervisorOnly ? null : profileDetails.teamId,
                    teamName: ifSupervisorOnly ? null : profileDetails.teamName,
                    roles: profileDetails.roles,
                    siteId: STORE.agent.siteId,
                    auxCodeId: this.getDefaultAuxCode() || ""
                };
                const prefData = {
                    extensionNumber: profileDetails.extensionNumber,
                    dialNumberValueForNonUS: profileDetails.dialNumberValueForNonUS,
                    isInternational: profileDetails.isInternational,
                    teamName: profileDetails.teamName,
                    teamId: profileDetails.teamId,
                    internationalPhoneNumber: stripSpecialChar(profileDetails.phoneNumber),
                    countryCallingCode: profileDetails.countryCallingCode,
                    countryCodeValue: profileDetails.countryCodeValue,
                    isRememberMyCredentials: profileDetails.isRememberMyCredentials
                };
                if (this.featureFlags.isWebRTCEnabled && STORE.agent.role === SYSTEM_ROLES.AGENT) {
                    if (profileDetails.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
                        userStationLoginData.deviceId = `webrtc-${STORE.agent.agentId}`;
                        userStationLoginData.dialNumber = `webrtc-${STORE.agent.agentId}`;
                    }
                    else {
                        userStationLoginData.deviceId = userStationLoginData.dialNumber;
                    }
                    if (this.featureFlags.isDesktopEmergencyNotificationEnabled) {
                        userStationLoginData.isEmergencyModalAlreadyDisplayed = profileDetails.isEmergencyModalAlreadyDisplayed;
                    }
                    userStationLoginData.deviceType = profileDetails.deviceType;
                    prefData.deviceType = profileDetails.deviceType;
                }
                const logoutPrefData = Object.assign(Object.assign({}, userStationLoginData), prefData);
                if (SERVICE.conf.profile &&
                    SERVICE.conf.profile.skillProfileId !== "0" &&
                    SERVICE.conf.profile.skillProfileId !== "null") {
                    userStationLoginData.skillProfileId = SERVICE.conf.profile.skillProfileId;
                }
                logger.info("[UpdateProfile] Logout success for Update Profile");
                yield this.login(userStationLoginData, logoutPrefData);
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SIGN_OUT, {
                    [SERVICE.telemetry.MIX_PROPS.SIGN_OUT_METHOD]: "Agent",
                    Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
                });
                this.setChangeProfileSetingsLoading(false);
            }))
                .catch(err => {
                this.setChangeProfileSetingsLoading(false);
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SIGN_OUT, {
                    [SERVICE.telemetry.MIX_PROPS.SIGN_OUT_METHOD]: "Agent",
                    Status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
                    Reason: "AQMLogoutFailure"
                });
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.CHANGE_PROFILE_SETTINGS, {
                    status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
                    ChangedProfile: profileDetails,
                    Reason: "Aqm logout Failed For Update Profile"
                });
                logger.error(`event=AQMLogoutFailure | [Tracking ID: ${getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(err) : ""}] Aqm logout Failed For Update Profile ${err}`, err);
                STORE.session.errorModal.setErrorDetails({
                    detail: {
                        modalType: "dialog",
                        // AgentLogoutFailed
                        message: t("app:updateProfileSettings.updateProfileFailed"),
                        trackingId: getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(err) : ""
                    }
                });
            });
        });
    }
    logoutFromCurrentTeam(selectedTeam) {
        return __awaiter(this, void 0, void 0, function* () {
            // Save DN in local storage
            localStorage.setItem("agent-dn-number", STORE.agent.dnNumber);
            this.setChangeTeamLoading(true);
            logger.info("[ChangeTeam] Initiating Logout for Change Teams");
            SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.SIGN_OUT);
            const logoutResponse = SERVICE.aqm.agent.logout({ data: { logoutReason: "User requested logout" } });
            logoutResponse
                .then(() => __awaiter(this, void 0, void 0, function* () {
                const stationLoginData = {
                    dn: this.getAgentDN() || "",
                    usesOtherDN: STORE.agent.usesOtherDN,
                    teamId: selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.teamId,
                    teamName: selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.teamName,
                    siteId: STORE.agent.siteId,
                    auxCodeId: this.getDefaultAuxCode() || "",
                    isExtension: STORE.agent.isExtension
                };
                if (SERVICE.conf.profile &&
                    SERVICE.conf.profile.skillProfileId !== "0" &&
                    SERVICE.conf.profile.skillProfileId !== "null") {
                    stationLoginData.skillProfileId = SERVICE.conf.profile.skillProfileId;
                }
                logger.info("[ChangeTeam] Logout success for Change Teams");
                yield this.loginAgentWithNewTeam(stationLoginData);
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SIGN_OUT, {
                    [SERVICE.telemetry.MIX_PROPS.SIGN_OUT_METHOD]: "Agent",
                    Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
                });
                this.setChangeTeamLoading(false);
            }))
                .catch(err => {
                this.setChangeTeamLoading(false);
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SIGN_OUT, {
                    [SERVICE.telemetry.MIX_PROPS.SIGN_OUT_METHOD]: "Agent",
                    Status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
                    Reason: "AQMLogoutFailure"
                });
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.CHANGE_TEAM, {
                    status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
                    ChangedTeam: selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.teamName,
                    Reason: "Aqm logout Failed For Change Teams"
                });
                logger.error(`event=AQMLogoutFailure | [Tracking ID: ${getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(err) : ""}] Aqm logout Failed For Change Teams ${err}`, err);
                STORE.session.errorModal.setErrorDetails({
                    detail: {
                        modalType: "dialog",
                        message: t("app:changeTeam.changeTeamFailed"),
                        trackingId: getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(err) : ""
                    }
                });
            });
        });
    }
    /**
     * Actions
     */
    updateTitle(title) {
        this.title = title;
    }
    updateAppTitleAsImage(appTitleAsImage) {
        this.appTitleAsImage = appTitleAsImage;
    }
    updateLogo(logoUrl) {
        this.logo = logoUrl;
    }
    updateTaskPageIllustration(url) {
        this.taskPageIllustration = url;
    }
    updateStopNavigateOnAcceptTask(isEnabled) {
        this.stopNavigateOnAcceptTask = isEnabled;
    }
    updateCollapseVoiceIntPane(isEnabled) {
        this.collapseVoiceIntPane = isEnabled;
    }
    updateUserVoiceIntPaneState(isEnabled) {
        this.isVoiceIntPaneUpdatedByUser = isEnabled;
    }
    toggleDarkMode(isEnabled) {
        this.darkMode = isEnabled;
        localStorage.setItem(DARK_MODE_KEY, `${isEnabled}`);
    }
    updateWebexEnabled(isEnabled) {
        this.webexEnabled = isEnabled;
    }
    updateWebexEventRegistrationStatus(isRegistered) {
        this.webexEventRegistered = isRegistered;
    }
    updateWebexShowModal(isShow) {
        this.webexShowModal = isShow;
    }
    updateWebexMeetingTimeStamp(timeStamp) {
        this.webexMeetingTimeStamp = timeStamp;
    }
    updateWebexMinimized(isShow) {
        this.webexMinimized = isShow;
    }
    updateLanguage(lng) {
        this.language = lng;
    }
    updateWxmEnabled(isEnabled) {
        this.wxmEnabled = isEnabled;
    }
    updateWebexDnd(isEnabled) {
        this.webexDndEnabled = isEnabled;
    }
    updateStationLoginOpen(isOpen) {
        this.isStationLoginOpen = isOpen;
    }
    updateEmergencyModalOpen(isOpen) {
        this.isEmergencyModalOpen = isOpen;
    }
    updateStationLoginSuccess(isSuccess) {
        this.isStationLoginSuccess = isSuccess;
    }
    setChangeTeamsLoginFailed(isError) {
        this.changeTeamsError = isError;
    }
    setChangeProfileSettingsLoginFailed(isError) {
        this.changeProfileSettingsError = isError;
    }
    updateIMIFeatureFlag(status) {
        this.featureFlags.isImiChatWidgetEnabled = status;
    }
    updateUserModel(accessToken, expiresIn) {
        const newModel = {
            ".expires": undefined,
            ".issued": undefined,
            access_token: accessToken,
            email: "",
            expires_in: expiresIn,
            hash: "",
            managedBy: "",
            primaryRole: "",
            station: "",
            token_type: "bearer",
            userName: "",
            isCIToken: true
        };
        this.userModel = newModel;
    }
    updateFeatureFlags(featureFlags) {
        if (featureFlags) {
            this.featureFlags = Object.assign(Object.assign({}, this.featureFlags), featureFlags);
        }
    }
    updatePciCompliance(pciComplianceSettings) {
        if (pciComplianceSettings) {
            this.pciCompliance = pciComplianceSettings;
        }
    }
    updatePciAllowedAttachmentTypes(allowedAttachmentTypes) {
        if (allowedAttachmentTypes) {
            this.pciAllowedAttachmentTypes = allowedAttachmentTypes;
        }
    }
    toggleTaskAreaCollapse() {
        this.taskAreaCollapsed = !this.taskAreaCollapsed;
        Cookies.set(TASK_AREA_COOKIES, `${this.taskAreaCollapsed}`);
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.TASK_PANE_TOGGLE, {
            [SERVICE.telemetry.MIX_PROPS.TASK_PANE_STATE]: this.taskAreaCollapsed ? "Minimized" : "Expanded",
            Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
        });
        if (!this.taskAreaCollapsed && window.innerWidth < IPAD_LANDSCAPE_LOWER_LIMIT && !this.auxilliaryPanelCollapsed) {
            // Collapse Aux panel if Task List is expanded in < 1024 screen size
            this.toggleAuxilliaryPanelCollapse();
        }
    }
    toggleAuxilliaryPanelCollapse() {
        this.auxilliaryPanelCollapsed = !this.auxilliaryPanelCollapsed;
        Cookies.set(AUX_PANEL_COOKIES, `${this.auxilliaryPanelCollapsed}`);
        if (!this.auxilliaryPanelCollapsed && window.innerWidth < IPAD_LANDSCAPE_LOWER_LIMIT && !this.taskAreaCollapsed) {
            // Collapse Task List if Aux panel is expanded in < 1024 screen size
            this.toggleTaskAreaCollapse();
        }
    }
    setShowAuxPanelAsOverlay(showAuxPanelAsOverlayParam) {
        this.showAuxPanelAsOverlay = showAuxPanelAsOverlayParam;
    }
    checkPwaInstall() {
        return __awaiter(this, void 0, void 0, function* () {
            if (AGENTX_SW_CONTROLLER.isInstallPending && !this.wasAskedPwaInstall) {
                const res = yield STORE.generalNotifications.fireCheckPWAInstall();
                this.setAskedPwaInstall();
                if (res) {
                    AGENTX_SW_CONTROLLER.installConfirm();
                }
                else {
                    AGENTX_SW_CONTROLLER.installDismiss();
                }
            }
        });
    }
    setAskedPwaInstall() {
        this.wasAskedPwaInstall = true;
        Cookies.set(WAS_ASKED_PWA_INSTALL_COOKIES, `${this.wasAskedPwaInstall}`);
    }
    updateMicrosoftOrgConfig(data) {
        var _a, _b, _c, _d, _e, _f;
        const accountDetails = { tenantId: (_b = (_a = data.accountDetails) === null || _a === void 0 ? void 0 : _a.tenantId) !== null && _b !== void 0 ? _b : "" };
        const orgResponse = {
            active: (_c = data === null || data === void 0 ? void 0 : data.active) !== null && _c !== void 0 ? _c : false,
            accountDetails,
            showUserDetails: (_d = data === null || data === void 0 ? void 0 : data.showUserDetails) !== null && _d !== void 0 ? _d : false,
            stateSynchronization: (_e = data === null || data === void 0 ? void 0 : data.stateSynchronization) !== null && _e !== void 0 ? _e : false,
            idleCodes: (_f = data === null || data === void 0 ? void 0 : data.idleCodes) !== null && _f !== void 0 ? _f : {}
        };
        this.microsoftOrgConfig = orgResponse;
    }
    updateWebexOrgConfig(orgConfig) {
        this.webexOrgConfig = orgConfig;
    }
    setChangeTeamLoading(changeTeamLoading) {
        this.changeTeamLoading = changeTeamLoading;
    }
    changeAgentTeam(selectedTeam) {
        this.changeTeamLoading = true;
        this.logoutFromCurrentTeam(selectedTeam);
    }
    updateProfileSettings(profileDetails) {
        this.changeProfileSettingsLoading = true;
        this.logout(profileDetails);
    }
    setChangeProfileSetingsLoading(changeProfileSettingsLoading) {
        this.changeProfileSettingsLoading = changeProfileSettingsLoading;
    }
    setImiScriptLoad() {
        this.imiScriptLoaded = true;
    }
    setIsEnvironmentProd(isProd) {
        this.isEnvironmentProd = isProd;
    }
    setDomainUrl(url) {
        this.domainUrl = url;
    }
    setDatacenter(datacenter) {
        this.datacenter = datacenter;
    }
}
__decorate([
    observable
], ModuleApp.prototype, "title", void 0);
__decorate([
    observable
], ModuleApp.prototype, "logo", void 0);
__decorate([
    observable
], ModuleApp.prototype, "appTitleAsImage", void 0);
__decorate([
    observable
], ModuleApp.prototype, "taskAreaCollapsed", void 0);
__decorate([
    observable
], ModuleApp.prototype, "auxilliaryPanelCollapsed", void 0);
__decorate([
    observable
], ModuleApp.prototype, "defaultMaximizeAreaName", void 0);
__decorate([
    observable
], ModuleApp.prototype, "darkThemeEnabled", void 0);
__decorate([
    observable
], ModuleApp.prototype, "darkMode", void 0);
__decorate([
    observable
], ModuleApp.prototype, "wasAskedPwaInstall", void 0);
__decorate([
    observable
], ModuleApp.prototype, "showAuxPanelAsOverlay", void 0);
__decorate([
    observable
], ModuleApp.prototype, "changeTeamLoading", void 0);
__decorate([
    observable
], ModuleApp.prototype, "isStationLoginOpen", void 0);
__decorate([
    observable
], ModuleApp.prototype, "isEmergencyModalOpen", void 0);
__decorate([
    observable
], ModuleApp.prototype, "changeTeamsError", void 0);
__decorate([
    observable
], ModuleApp.prototype, "isStationLoginSuccess", void 0);
__decorate([
    observable
], ModuleApp.prototype, "changeProfileSettingsLoading", void 0);
__decorate([
    observable
], ModuleApp.prototype, "changeProfileSettingsError", void 0);
__decorate([
    observable
], ModuleApp.prototype, "stopNavigateOnAcceptTask", void 0);
__decorate([
    observable
], ModuleApp.prototype, "collapseVoiceIntPane", void 0);
__decorate([
    observable
], ModuleApp.prototype, "isVoiceIntPaneUpdatedByUser", void 0);
__decorate([
    observable
], ModuleApp.prototype, "taskPageIllustration", void 0);
__decorate([
    observable
], ModuleApp.prototype, "isRTDIntialized", void 0);
__decorate([
    observable
], ModuleApp.prototype, "microsoftOrgConfig", void 0);
__decorate([
    observable
], ModuleApp.prototype, "webexOrgConfig", void 0);
__decorate([
    observable
], ModuleApp.prototype, "datacenter", void 0);
__decorate([
    observable
], ModuleApp.prototype, "wxmEnabled", void 0);
__decorate([
    observable
], ModuleApp.prototype, "userModel", void 0);
__decorate([
    observable
], ModuleApp.prototype, "webexEnabled", void 0);
__decorate([
    observable
], ModuleApp.prototype, "webexShowModal", void 0);
__decorate([
    observable
], ModuleApp.prototype, "webexMinimized", void 0);
__decorate([
    observable
], ModuleApp.prototype, "language", void 0);
__decorate([
    observable
], ModuleApp.prototype, "webexMeetingTimeStamp", void 0);
__decorate([
    observable
], ModuleApp.prototype, "webexDndEnabled", void 0);
__decorate([
    observable
], ModuleApp.prototype, "webexEventRegistered", void 0);
__decorate([
    observable
], ModuleApp.prototype, "isEnvironmentProd", void 0);
__decorate([
    observable
], ModuleApp.prototype, "domainUrl", void 0);
__decorate([
    observable
], ModuleApp.prototype, "featureFlags", void 0);
__decorate([
    observable
], ModuleApp.prototype, "pciCompliance", void 0);
__decorate([
    observable
], ModuleApp.prototype, "pciAllowedAttachmentTypes", void 0);
__decorate([
    observable
], ModuleApp.prototype, "imiScriptLoaded", void 0);
__decorate([
    computed
], ModuleApp.prototype, "chatConfigs", null);
__decorate([
    computed
], ModuleApp.prototype, "multipartyOrPersistsFF", null);
__decorate([
    computed
], ModuleApp.prototype, "pciSettings", null);
__decorate([
    computed
], ModuleApp.prototype, "pciAllowedAttachments", null);
__decorate([
    computed
], ModuleApp.prototype, "emailConfigs", null);
__decorate([
    computed
], ModuleApp.prototype, "imiConfigs", null);
__decorate([
    computed
], ModuleApp.prototype, "cssDynamicVars", null);
__decorate([
    action
], ModuleApp.prototype, "updateIsRTDInitializedFlag", null);
__decorate([
    action
], ModuleApp.prototype, "updateTitle", null);
__decorate([
    action
], ModuleApp.prototype, "updateAppTitleAsImage", null);
__decorate([
    action
], ModuleApp.prototype, "updateLogo", null);
__decorate([
    action
], ModuleApp.prototype, "updateTaskPageIllustration", null);
__decorate([
    action
], ModuleApp.prototype, "updateStopNavigateOnAcceptTask", null);
__decorate([
    action
], ModuleApp.prototype, "updateCollapseVoiceIntPane", null);
__decorate([
    action
], ModuleApp.prototype, "updateUserVoiceIntPaneState", null);
__decorate([
    action
], ModuleApp.prototype, "toggleDarkMode", null);
__decorate([
    action
], ModuleApp.prototype, "updateWebexEnabled", null);
__decorate([
    action
], ModuleApp.prototype, "updateWebexEventRegistrationStatus", null);
__decorate([
    action
], ModuleApp.prototype, "updateWebexShowModal", null);
__decorate([
    action
], ModuleApp.prototype, "updateWebexMeetingTimeStamp", null);
__decorate([
    action
], ModuleApp.prototype, "updateWebexMinimized", null);
__decorate([
    action
], ModuleApp.prototype, "updateLanguage", null);
__decorate([
    action
], ModuleApp.prototype, "updateWxmEnabled", null);
__decorate([
    action
], ModuleApp.prototype, "updateWebexDnd", null);
__decorate([
    action
], ModuleApp.prototype, "updateStationLoginOpen", null);
__decorate([
    action
], ModuleApp.prototype, "updateEmergencyModalOpen", null);
__decorate([
    action
], ModuleApp.prototype, "updateStationLoginSuccess", null);
__decorate([
    action
], ModuleApp.prototype, "setChangeTeamsLoginFailed", null);
__decorate([
    action
], ModuleApp.prototype, "setChangeProfileSettingsLoginFailed", null);
__decorate([
    action
], ModuleApp.prototype, "updateIMIFeatureFlag", null);
__decorate([
    action
], ModuleApp.prototype, "updateUserModel", null);
__decorate([
    action
], ModuleApp.prototype, "updateFeatureFlags", null);
__decorate([
    action
], ModuleApp.prototype, "updatePciCompliance", null);
__decorate([
    action
], ModuleApp.prototype, "updatePciAllowedAttachmentTypes", null);
__decorate([
    action
], ModuleApp.prototype, "toggleTaskAreaCollapse", null);
__decorate([
    action
], ModuleApp.prototype, "toggleAuxilliaryPanelCollapse", null);
__decorate([
    action
], ModuleApp.prototype, "setShowAuxPanelAsOverlay", null);
__decorate([
    action
], ModuleApp.prototype, "checkPwaInstall", null);
__decorate([
    action
], ModuleApp.prototype, "setAskedPwaInstall", null);
__decorate([
    action
], ModuleApp.prototype, "updateMicrosoftOrgConfig", null);
__decorate([
    action
], ModuleApp.prototype, "updateWebexOrgConfig", null);
__decorate([
    action
], ModuleApp.prototype, "setChangeTeamLoading", null);
__decorate([
    action
], ModuleApp.prototype, "changeAgentTeam", null);
__decorate([
    action
], ModuleApp.prototype, "updateProfileSettings", null);
__decorate([
    action
], ModuleApp.prototype, "setChangeProfileSetingsLoading", null);
__decorate([
    action
], ModuleApp.prototype, "setImiScriptLoad", null);
__decorate([
    action
], ModuleApp.prototype, "setIsEnvironmentProd", null);
__decorate([
    action
], ModuleApp.prototype, "setDomainUrl", null);
__decorate([
    action
], ModuleApp.prototype, "setDatacenter", null);
export const app = new ModuleApp();
