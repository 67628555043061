var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { ToasterNotificationItemContent } from "@uuip/unified-ui-platform-common-components";
import { Notifications, createNotifications } from "@uuip/unified-ui-platform-sdk";
import { action, observable } from "mobx";
const DEFAULT_AUTO_DISMISS_TIMER = 8;
const DEFAULT_MAXIMUM_TOASTER_COUNT = 3;
const SILENT_NOTIFICATIONS = "desktop-silent-notifications";
const NOTIFICATION_SETTINGS = "desktop-notification-settings";
const DEFAULT_NOTIFICATIONS_SETTINGS = { settings: true, silent: false };
export class ModuleGeneralNotifications {
    constructor(notifications) {
        this.isNotificationsEnabled = true;
        this.isSilentNotificationsEnabled = false;
        this.countAdded = 0;
        this.countPending = 0;
        this.countActivated = 0;
        this.countDeactivated = 0;
        this.webexNotificationCount = 0;
        this.getPresenceNotifData = (titleType, retryCallback) => {
            let notificationType = Notifications.ItemMeta.Type.Success;
            let notificationTitle = "app:notifications.mercurySyncSuccessTitle";
            let notificationData = new ToasterNotificationItemContent.DataController({
                text: t("app:notifications.mercurySyncSuccessMessage")
            });
            let showNotification = true;
            if (titleType === "sync_successful") {
                showNotification = false;
            }
            else if (titleType === "mercury_retrying") {
                notificationType = Notifications.ItemMeta.Type.Error;
                notificationTitle = "app:notifications.mercurySyncFailedTitle";
                notificationData = new ToasterNotificationItemContent.DataController({
                    text: t("app:notifications.stateSyncFailedMessage")
                });
            }
            else if (titleType === "mercury_failure") {
                notificationType = Notifications.ItemMeta.Type.Error;
                notificationTitle = "app:notifications.mercurySyncRetryFailedTitle";
                notificationData = new ToasterNotificationItemContent.DataController({
                    text: t("app:notifications.mercurySyncRetryFailedMessage")
                });
            }
            else if (titleType === "sync_failure") {
                notificationType = Notifications.ItemMeta.Type.Error;
                notificationTitle = "app:notifications.stateSyncFailedTitle";
                notificationData = new ToasterNotificationItemContent.DataController({
                    text: t("app:notifications.stateSyncFailedMessage"),
                    linkName: t("Retry synchronization"),
                    linkHandler: () => {
                        if (retryCallback) {
                            retryCallback();
                        }
                    }
                });
            }
            return { notificationType, notificationTitle, notificationData, showNotification };
        };
        // Injecting Notifications.Service instance
        this.notifications = notifications;
        this.restoreNotificationsSettings();
        // Listening for all service status events
        Notifications.ServiceMeta.STATUS_EVENTS.forEach(eventName => {
            notifications.addEventListener(eventName, () => this.updateStoreNotificationsData());
        });
        // Restoring notifications sequence
        this.restoreNotificationsOnReload();
    }
    setWebexNotificationCount(count) {
        this.webexNotificationCount = count;
    }
    clearAll() {
        this.notifications.deactivateAll(Notifications.ItemMeta.DeactivateEventReason.UserNegative);
        this.notifications.removeAll();
        this.countAdded = 0;
        this.countPending = 0;
        this.countActivated = 0;
        this.countDeactivated = 0;
        sessionStorage.removeItem(SILENT_NOTIFICATIONS);
    }
    isSelectedTaskHasChatNotifications(n, taskId) {
        if (n.data instanceof ToasterNotificationItemContent.DataController) {
            return n.data.taskId && n.data.taskId === taskId;
        }
        return false;
    }
    deactivateReadMessagesFromNotifications(taskId) {
        const pendingSilentChatNotifications = this.notifications.added.chat.filter(n => this.isSelectedTaskHasChatNotifications(n, taskId));
        this.notifications.deactivate(pendingSilentChatNotifications, Notifications.ItemMeta.DeactivateEventReason.UserNegative);
        // changes to remove notification related to particular task when agents selects it.
        this.notifications.remove(pendingSilentChatNotifications);
        sessionStorage.setItem(SILENT_NOTIFICATIONS, JSON.stringify(this.notifications.pended.silent.map(n => ({
            data: {
                title: n.title,
                mode: n.mode,
                type: n.type,
                data: n.data,
                timestamp: n.timestamp
            },
            options: n.options
        }))));
    }
    restoreNotificationsOnReload() {
        const silentNotificationsFromSessionJson = sessionStorage.getItem(SILENT_NOTIFICATIONS);
        if (silentNotificationsFromSessionJson) {
            try {
                const parsedSilentNotifications = JSON.parse(silentNotificationsFromSessionJson);
                if (Array.isArray(parsedSilentNotifications) && parsedSilentNotifications.length > 0) {
                    const rawNotifications = parsedSilentNotifications.map(n => {
                        if (n.data && typeof n.data.data === "object") {
                            n.data.data = new ToasterNotificationItemContent.DataController(Object.assign(n.data.data));
                        }
                        return {
                            data: n.data,
                            options: n.options
                        };
                    });
                    this.fire(rawNotifications);
                }
            }
            catch (e) {
                // remove bad data which might cause reload issue
                sessionStorage.removeItem(SILENT_NOTIFICATIONS);
                return;
            }
        }
    }
    updateStoreNotificationsData() {
        this.countAdded = this.notifications.added.length;
        //
        this.countPending = this.notifications.pended.length;
        this.countActivated = this.notifications.activated.length;
        this.countDeactivated = this.notifications.deactivated.length;
        sessionStorage.setItem(SILENT_NOTIFICATIONS, JSON.stringify(this.notifications.pended.silent.map(el => ({
            data: {
                title: el.title,
                mode: el.mode,
                type: el.type,
                data: el.data,
                timestamp: el.timestamp
            },
            options: el.options
        }))));
    }
    saveNotificationsSettings(notificationsSettings) {
        localStorage.setItem(NOTIFICATION_SETTINGS, JSON.stringify(notificationsSettings));
    }
    restoreNotificationsSettings() {
        const restoredNotificationsSettingsJson = localStorage.getItem(NOTIFICATION_SETTINGS);
        if (restoredNotificationsSettingsJson) {
            const restoredNotificationsSettings = JSON.parse(restoredNotificationsSettingsJson);
            if (restoredNotificationsSettings) {
                this.isNotificationsEnabled = restoredNotificationsSettings.settings;
                this.isSilentNotificationsEnabled = restoredNotificationsSettings.silent;
            }
        }
        else {
            this.saveNotificationsSettings(DEFAULT_NOTIFICATIONS_SETTINGS);
        }
        if (!this.isNotificationsEnabled) {
            this.clearAll();
        }
    }
    fire(raw) {
        // Only fire when notifications are enabled
        if (this.isNotificationsEnabled) {
            const _raw = Array.isArray(raw) ? raw : [raw];
            // Silentify by settings
            _raw.forEach(_r => {
                if (this.isSilentNotificationsEnabled && _r.data.mode !== Notifications.ItemMeta.Mode.Silent) {
                    _r.data.mode = Notifications.ItemMeta.Mode.Silent;
                }
            });
            return this.notifications.add(_raw);
        }
        return undefined;
    }
    fireNewChatMessage(data, type, iconUrl, taskId) {
        const notificationData = {
            type: Notifications.ItemMeta.Type.Chat,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: t("app:notifications.newChatMessageTitle") + data.actorName,
            data: new ToasterNotificationItemContent.DataController({
                type,
                text: data.isAttachment ? t("app:notifications.newChatAttachmentData") : data.message ? data.message : "",
                taskId,
                iconDetail: Object.assign({}, (iconUrl && { iconUrl }))
            })
        };
        this.fireNotificationEvent(notificationData);
    }
    fireChatEnded(data) {
        const notificationData = {
            type: Notifications.ItemMeta.Type.Warn,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: t("app:notifications.customerLeftTitle") + data.actorName,
            data: t("app:notifications.customerLeftData")
        };
        this.fireNotificationEvent(notificationData);
    }
    fireScreenPop(conf) {
        this.fireNotificationEvent(conf);
    }
    fireShortcutKeyConflict(title, data) {
        const notificationData = {
            type: Notifications.ItemMeta.Type.Warn,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title,
            data
        };
        this.fireNotificationEvent(notificationData);
    }
    fireCheckPWAInstall() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                const notificationData = {
                    type: Notifications.ItemMeta.Type.Info,
                    mode: Notifications.ItemMeta.Mode.AutoDismiss,
                    title: t("app:notifications.desktopapplicationPopTitle"),
                    data: new ToasterNotificationItemContent.DataController({
                        text: t("app:notifications.desktopapplicationData"),
                        linkName: t("app:notifications.desktopapplicationLink"),
                        localization: { closeButtonText: t("app:notifications.close") },
                        linkHandler: (n) => {
                            resolve(true);
                            n.pending();
                        }
                    })
                };
                const notificationOptions = {
                    AUTO_DISMISS_TIMEOUT: 15 * 60 * 1000 // 15 mins
                };
                const firedNotifications = this.fireNotificationEvent(notificationData, notificationOptions);
                if (firedNotifications) {
                    const [n] = firedNotifications;
                    const statusUpdateListener = (status) => {
                        if (status === Notifications.ItemMeta.Status.Pended) {
                            resolve(false);
                            n.deactivate(Notifications.ItemMeta.DeactivateEventReason.UserNeutral);
                        }
                        if (status === Notifications.ItemMeta.Status.Deactivated) {
                            resolve(false);
                            n.removeEventListener("statusUpdate", statusUpdateListener);
                        }
                    };
                    n.addEventListener("statusUpdate", statusUpdateListener);
                }
            });
        });
    }
    fireNotificationEvent(notificationData, options) {
        return this.fire({
            data: Object.assign({}, notificationData),
            options: Object.assign({}, (options || {}))
        });
    }
    updateNotificationSettings(isNotificationsEnabled, isSilentNotificationsEnabled) {
        const updatedNotificationSettings = { settings: isNotificationsEnabled, silent: isSilentNotificationsEnabled };
        this.isNotificationsEnabled = isNotificationsEnabled;
        this.isSilentNotificationsEnabled = isSilentNotificationsEnabled;
        this.saveNotificationsSettings(updatedNotificationSettings);
        if (!this.isNotificationsEnabled) {
            this.clearAll();
        }
    }
    updateConfig(toasterTimer, maximumToasterCount) {
        const updatedConfig = {
            ACTIVATED_ITEM_MODE_LIMIT: {
                autodismiss: maximumToasterCount || maximumToasterCount === 0 ? maximumToasterCount : DEFAULT_MAXIMUM_TOASTER_COUNT
            },
            AUTO_DISMISS_TIMEOUT: (toasterTimer || toasterTimer === 0 ? toasterTimer : DEFAULT_AUTO_DISMISS_TIMER) * 1000
        };
        this.notifications.updateConfig(updatedConfig);
    }
    triggerPresenceNotification(callingValue, retryCallback) {
        const { notificationType, notificationTitle, notificationData, showNotification } = retryCallback
            ? this.getPresenceNotifData(STORE.agent.presenceServiceStatus.serviceStatus, retryCallback)
            : this.getPresenceNotifData(STORE.agent.presenceServiceStatus.serviceStatus);
        if (!showNotification || callingValue !== STORE.agent.presenceServiceStatus.serviceStatus) {
            return;
        }
        const notificationRawData = {
            type: notificationType,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: t(notificationTitle),
            data: notificationData
        };
        this.fireNotificationEvent(notificationRawData);
        STORE.browserNotifications.firebrowserNotification(`${t(notificationTitle)} : ${t(notificationData.text)}`);
    }
}
__decorate([
    observable
], ModuleGeneralNotifications.prototype, "isNotificationsEnabled", void 0);
__decorate([
    observable
], ModuleGeneralNotifications.prototype, "isSilentNotificationsEnabled", void 0);
__decorate([
    observable
], ModuleGeneralNotifications.prototype, "countAdded", void 0);
__decorate([
    observable
], ModuleGeneralNotifications.prototype, "countPending", void 0);
__decorate([
    observable
], ModuleGeneralNotifications.prototype, "countActivated", void 0);
__decorate([
    observable
], ModuleGeneralNotifications.prototype, "countDeactivated", void 0);
__decorate([
    observable
], ModuleGeneralNotifications.prototype, "webexNotificationCount", void 0);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "setWebexNotificationCount", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "clearAll", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "deactivateReadMessagesFromNotifications", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "restoreNotificationsOnReload", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "updateStoreNotificationsData", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "saveNotificationsSettings", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "restoreNotificationsSettings", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "fire", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "fireNewChatMessage", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "fireChatEnded", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "fireScreenPop", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "fireShortcutKeyConflict", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "fireCheckPWAInstall", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "fireNotificationEvent", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "updateNotificationSettings", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "updateConfig", null);
__decorate([
    action
], ModuleGeneralNotifications.prototype, "triggerPresenceNotification", null);
const generalNotificationInit = () => {
    return createNotifications({
        ACTIVATED_ITEM_MODE_LIMIT: {
            autodismiss: DEFAULT_MAXIMUM_TOASTER_COUNT
        },
        AUTO_DISMISS_TIMEOUT: DEFAULT_AUTO_DISMISS_TIMER * 1000
    });
};
/**
 * Set up notification host
 */
export const generalNotifications = new ModuleGeneralNotifications(generalNotificationInit());
