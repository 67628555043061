import { createLogger as createBaseLogger } from "@uuip/unified-ui-platform-sdk";
import { DateTime } from "luxon";
// Mask the values of keys mentioned in this object
const objKeysToBeMasked = {
    ani: true,
    dn: true,
    agent_ani: true,
    dnis: true,
    email: true,
    callAssociatedData: true,
    title: true,
    text: true,
    displayName: true,
    sentBy: true,
    agentName: true,
    agentMailId: true,
    userName: true,
    emails: true,
    sipAddresses: true
};
const logger = createBaseLogger("AgentX", objKeysToBeMasked);
const KEY = "YTdiYTEwZjctYzIxYy00YzhhLTgzN2ItNDQ4MDNhOGMxZmEy";
var Level;
(function (Level) {
    Level[Level["Trace"] = 1] = "Trace";
    Level[Level["Debug"] = 2] = "Debug";
    Level[Level["Warn"] = 3] = "Warn";
    Level[Level["Error"] = 4] = "Error";
    Level[Level["Fatal"] = 5] = "Fatal";
})(Level || (Level = {}));
const EVENT_KEY = "event=";
const ERROR_KEY = "error";
const FAILED_KEY = "failed";
const generateUUID = () => {
    let d = DateTime.utc().toMillis();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (d + Math.random() * 16) % 16 | 0; // eslint-disable-line no-bitwise
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16); // eslint-disable-line no-bitwise
    });
};
const loggerId = generateUUID();
logger.info(`[Agentx] ClientLogSessionID =  ${loggerId}`);
const kibana = (logs, logName, logValue, logPrefix) => {
    const HOST = process.env.AGENTX_LOGGER || "";
    if (HOST === "") {
        return;
    }
    fetch(`${process.env.AGENTX_LOGGER}/api/pushLogs`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            Authorization: KEY,
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*"
        },
        body: JSON.stringify(logs)
    })
        .then(res => {
        if (res.ok) {
            localStorage.setItem(logName, logValue);
            logger.info(`${logPrefix} pushlogs api success`);
        }
        else {
            logger.warn(`${logPrefix} pushlogs api unsuccessful`);
        }
    })
        .catch(e => {
        logger.error(`${logPrefix} pushlogs api failed`, e);
    });
};
/*eslint @typescript-eslint/no-unused-vars: "off"*/
export const logMiner = () => {
    const uuipLogs = sessionStorage.getItem("uuip-client-logs");
    const lastLogTs = localStorage.getItem("last-log-ts") || "";
    const agentId = sessionStorage.getItem("agentId") || "";
    const lastTs = lastLogTs && lastLogTs !== "" ? Number.parseInt(lastLogTs) : 0;
    const logsArr = uuipLogs && uuipLogs !== "" ? JSON.parse(`[${uuipLogs.split("<uuip-eol>").join(",")}]`) : [];
    const filteredLogs = logsArr.filter(value => {
        const searchMsg = value.msgs.join(" | ").toLowerCase();
        return (value.ts > lastTs &&
            (searchMsg.includes(EVENT_KEY) ||
                searchMsg.includes(FAILED_KEY) ||
                searchMsg.includes(ERROR_KEY) ||
                value.lvl > 2));
    });
    if (filteredLogs.length > 0) {
        const selectedLogs = [];
        for (let l = 0; l < filteredLogs.length; l++) {
            const record = filteredLogs[l];
            let logLevel;
            switch (record.lvl) {
                case Level.Debug:
                    logLevel = "info";
                    break;
                case Level.Warn:
                    logLevel = "warn";
                    break;
                case Level.Error:
                    logLevel = "error";
                    break;
                case Level.Fatal:
                    logLevel = "error";
                    break;
            }
            selectedLogs.push({
                level: logLevel,
                message: `ClientLogSessionID=${loggerId} | TimeStamp=${new Date(record.ts)} | agentId=${agentId} | ${record.msgs.join(" | ").replace("[object Object]", "")}`,
                timestamp: new Date(record.ts),
                clientLogSessionId: loggerId,
                agentId
            });
        }
        kibana(selectedLogs, "last-log-ts", logsArr[logsArr.length - 1].ts.toString(), "[LogMiner]");
    }
};
setInterval(logMiner, 180000);
function prometheusLogMiner() {
    const prometheusLogs = localStorage.getItem("prometheus-logs");
    if (prometheusLogs) {
        const prometheusLogsParsed = JSON.parse(prometheusLogs);
        kibana(prometheusLogsParsed, "prometheus-logs", "[]", "[PrometheusLogMiner]");
    }
}
setInterval(prometheusLogMiner, 300000);
export const createLogger = (prefix) => {
    class LoggerWrapper {
        constructor(pfx) {
            this.prefix = pfx;
        }
        error(...msg) {
            logger.error(this.prefix, ...msg);
        }
        info(...msg) {
            logger.info(this.prefix, ...msg);
        }
        warn(...msg) {
            logger.warn(this.prefix, ...msg);
        }
    }
    return new LoggerWrapper(prefix);
};
/** this will catch the unhandled java script error and update desktop standard loggers */
window.onerror = function (message, source, lineno, colno, error) {
    logger.error("Unhandled exception", message, source, lineno, colno, error);
};
