import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html, nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
const EmptyBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <!-- EMPTY -->
      <div class="customEmptyWrapper">
        ${STORE.app.taskPageIllustration
            ? html `
              <div
                class="customEmptySpace ${classMap({
                dark: STORE.app.darkMode
            })}"
              >
                <div class="customIllustration ">
                  <img
                    class="illustrationImage"
                    alt=${t("app:common.illustrationImage")}
                    src=${STORE.app.taskPageIllustration}
                  />
                </div>
              </div>
            `
            : nothing}
      </div>
    `,
        styles: css `
      uuip-wc-empty-state,
      .customEmptyWrapper {
        width: 100%;
        height: 100%;
        display: contents;
      }

      .customEmptySpace {
        grid-area: empty-state; /*grid*/
        margin: 8px;
        background-color: var(--md-secondary-white-bg-color);
        box-shadow: var(--common-box-shadow);
        border-radius: var(--border-radius-medium);
        overflow: hidden;
      }

      .customEmptySpace.dark {
        border: 1px solid var(--md-menu-overlay-border-color, --gray-20);
      }

      .customIllustration {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100%;
      }

      .illustrationImage {
        max-width: 100%;
        max-height: 100%;
      }

      @media (max-width: 736px) {
        .customEmptySpace {
          height: 100%;
        }
      }

      uuip-wc-empty-state::part(img) {
        max-width: 60%;
        width: 400px;
      }
    `
    });
};
export default EmptyBlock;
