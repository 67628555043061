import { t } from "@/mixins/i18nMixin";
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { isComputedProp, isObservableProp, reaction } from "mobx";
const logger = createLogger("[UtilsDynamic]");
class UtilsDynamic {
    constructor() {
        this.storeDataProvider = (k, upd) => {
            const s = k.split(".");
            const isStore = s[0] === "$STORE" && s.length > 1;
            const isI18N = s[0] === "$I18N" && s.length > 1;
            if (isI18N) {
                s.shift();
                const key = s.join(".");
                return { value: t(key) };
            }
            if (isStore) {
                const pName = s[s.length - 1];
                let pObj = STORE;
                for (let i = 1; i < s.length - 1; i++) {
                    if (s[i] in pObj) {
                        pObj = pObj[s[i]];
                    }
                    else {
                        pObj = undefined;
                        break;
                    }
                }
                if (pObj && pName in pObj && pObj[pName] !== undefined) {
                    let dispose;
                    if (isObservableProp(pObj, pName)) {
                        dispose = reaction(() => pObj[pName], x => upd(x));
                    }
                    else {
                        // TODO - it will work with computed property
                        // but Rost should check all MobX cases, combinations and nesting
                        let pObj2 = STORE;
                        for (let i = 1; i < s.length - 1; i++) {
                            if (s[i] in pObj2) {
                                pObj2 = pObj2[s[i]];
                                if (isComputedProp(pObj2, s[i + 1])) {
                                    const propName = s[i + 2];
                                    dispose = reaction(() => pObj2[s[i + 1]], x => (x && x[propName] ? upd(x[propName]) : null));
                                    break;
                                }
                            }
                            else {
                                break;
                            }
                        }
                    }
                    return { value: pObj[pName], dispose };
                }
                else {
                    logger.info(`dataProvider: Can't find ${k}`);
                }
            }
            return { value: k };
        };
    }
}
export const UTILS_DYNAMIC = new UtilsDynamic();
