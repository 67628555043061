import { BaseView } from "@/app/routing/views/_base-view/BaseView";
import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html } from "lit-html";
import "../../../components/DynamicArea";
import style from "./DynamicView.scss";
export const DynamicView = (page) => {
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: {
            dynamic: new Router.RouterView.Block({
                template: html `
          <dynamic-area
            class="dynamic-area"
            .area=${page}
            .isStationLoginSuccess=${STORE.app.isStationLoginSuccess}
          ></dynamic-area>
        `,
                styles: css `
          .dynamic-area {
            border-radius: 8px;
            grid-area: dynamic; /*grid*/
            height: 100%;
            margin: 8px;
            overflow-y: hidden;
          }
        `
            })
        },
        styles: [
            style,
            STORE.dynamic.showPersistentWidgets
                ? css `
            :host {
              grid-template-areas:
                "header header header header"
                "nav banner banner banner"
                "nav tasks common-control common-control"
                "nav tasks persistent-page-control persistent-page-control"
                "nav tasks dynamic dynamic";
              grid-template-columns: var(--nav-bar-width) auto auto 1fr;
              grid-template-rows: var(--horizontal-panel-height) auto auto 1fr;
            }
          `
                : css `
            :host {
              grid-template-areas:
                "header header header header"
                "nav banner banner banner"
                "nav tasks common-control common-control"
                "nav tasks dynamic dynamic"
                "nav tasks dynamic dynamic";
              grid-template-columns: var(--nav-bar-width) auto auto 1fr;
              grid-template-rows: var(--horizontal-panel-height) auto auto 1fr;
            }
          `
        ]
    });
};
