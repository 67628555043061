var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*eslint "no-console": "off"*/
import EventEmitter from "event-emitter";
export var AgentXServiceWorker;
(function (AgentXServiceWorker) {
    let MessageEventDataAction;
    (function (MessageEventDataAction) {
        MessageEventDataAction["SkipWaiting"] = "skipWaiting";
    })(MessageEventDataAction = AgentXServiceWorker.MessageEventDataAction || (AgentXServiceWorker.MessageEventDataAction = {}));
    class Controller {
        constructor() {
            this.emitter = EventEmitter();
            this.is_update_pending = false;
            this.is_inited = false;
            this.is_refreshing = false;
            this.deferredInstallPromptEvent = null;
            this.currWorker = null;
            this.nextWorker = null;
            this.currVersion = null;
            this.nextVersion = null;
            // Browser Events
            this.onControllerChangeHandler = () => {
                if (this.is_refreshing) {
                    return;
                }
                if (this.isUpdatePending) {
                    this.is_update_pending = false;
                    window.location.reload();
                    this.is_refreshing = true;
                }
            };
            this.onBeforeInstallPromptHandler = (e /*: BeforeInstallPromptEvent*/) => {
                // Prevent the mini-infobar from appearing on mobile
                e.preventDefault();
                //
                // Stash the event so it can be triggered later.
                this.deferredInstallPromptEvent = e;
                this.emit("AGENTX_SW_ASK_INSTALL");
            };
        }
        bindSwUpdateFlow(reg) {
            return __awaiter(this, void 0, void 0, function* () {
                console.log("AgentX SW binding self update events...");
                this.currWorker = reg.active;
                // Silently skipping waiting on page load
                if (reg.waiting) {
                    reg.waiting.postMessage({ action: MessageEventDataAction.SkipWaiting });
                }
                reg.addEventListener("updatefound", () => {
                    // An updated service worker has appeared in reg.installing!
                    this.nextWorker = reg.installing;
                    console.log("AgentX SW updatefound");
                    if (this.nextWorker) {
                        this.nextWorker.addEventListener("statechange", () => __awaiter(this, void 0, void 0, function* () {
                            console.log("AgentX next SW statechange: ", this.nextWorker);
                            if (this.nextWorker && this.nextWorker.state === "installed") {
                                // Has service worker state changed?
                                this.notifyPendingUpdate();
                            }
                        }));
                    }
                });
            });
        }
        notifyPendingUpdate() {
            // There is a new service worker available, show the notification
            if (navigator.serviceWorker.controller) {
                this.is_update_pending = true;
                // Emitting Update
                this.emit("AGENTX_SW_UPDATE_PENDING");
            }
        }
        bindSwBrowserEvents() {
            if ("serviceWorker" in navigator) {
                //
                // The event listener that is fired when the service worker updates
                // Here we reload the page
                navigator.serviceWorker.addEventListener("controllerchange", this.onControllerChangeHandler);
                // Catching mini-info bar
                window.addEventListener("beforeinstallprompt", this.onBeforeInstallPromptHandler);
            }
        }
        unbindSwBrowserEvents() {
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker.removeEventListener("controllerchange", this.onControllerChangeHandler);
                window.removeEventListener("beforeinstallprompt", this.onBeforeInstallPromptHandler);
            }
        }
        // Public
        // Init Service Worker
        init() {
            if (this.is_inited) {
                return;
            }
            // Registering Service Worker
            if ("serviceWorker" in navigator) {
                this.bindSwBrowserEvents();
                console.log("AgentX SW trying to register...");
                // main sw boot
                navigator.serviceWorker
                    .register("/service-worker.js")
                    .then(registration => {
                    console.log("AgentX SW registered: ", registration);
                    // OK
                    return this.bindSwUpdateFlow(registration);
                })
                    .catch(registrationError => {
                    console.log("AgentX SW registration failed: ", registrationError);
                    this.unbindSwBrowserEvents();
                });
            }
            else {
                console.log("Your browser does not support Service Worker...");
            }
            this.is_inited = true;
        }
        reload() {
            if (this.isUpdatePending) {
                this.nextWorker && this.nextWorker.postMessage({ action: MessageEventDataAction.SkipWaiting });
            }
            else {
                console.warn("No update pending...");
            }
        }
        // Install as standalone
        installConfirm() {
            if (this.deferredInstallPromptEvent) {
                this.deferredInstallPromptEvent /*: BeforeInstallPromptEvent*/
                    .prompt();
                this.deferredInstallPromptEvent /*: BeforeInstallPromptEvent*/.userChoice
                    .then((res) => {
                    console.log(res);
                    if (res.outcome === "accepted") {
                        console.log("User accepted the install prompt");
                    }
                    else {
                        console.log("User dismissed the install prompt.");
                    }
                });
            }
            else {
                console.warn("App already installed on your device...");
            }
        }
        installDismiss() {
            this.emit("AGENTX_SW_DISMISS_INSTALL");
        }
        //
        get isUpdatePending() {
            return this.is_update_pending;
        }
        get isInstallPending() {
            return !!this.deferredInstallPromptEvent;
        }
        // Events
        emit(eventName, ...args) {
            this.emitter.emit(eventName, ...args);
        }
        addEventListener(eventName, listener) {
            this.emitter.on(eventName, listener);
        }
        removeEventListener(eventName, listener) {
            this.emitter.off(eventName, listener);
        }
    }
    AgentXServiceWorker.Controller = Controller;
})(AgentXServiceWorker || (AgentXServiceWorker = {}));
const AGENTX_SW_CONTROLLER = new AgentXServiceWorker.Controller();
const init = () => {
    // Init sw controller
    AGENTX_SW_CONTROLLER.init();
    //bind sw controller CRITICAL events
    {
        // Bind app service-worker update event => this could be changed to styled modal but it should fire as much earlier as possible
        AGENTX_SW_CONTROLLER.addEventListener("AGENTX_SW_UPDATE_PENDING", () => {
            confirm(`New version of Webex Contact Center is available. Please reload to stay up to date with our latest upgrades.`) && AGENTX_SW_CONTROLLER.reload();
        });
    }
};
init();
export default AGENTX_SW_CONTROLLER;
