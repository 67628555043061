export const WEBEX = "webex";
export const NOTES = "notes";
export const OUTDIAL = "outdial";
export const NOTIFICATION = "notification";
export const ACTION_ITEM_LI = "action-item-li";
export const LIST_ITEM = "list-item";
export const DEFAULT_ACTION_ITEMS_ORDER = [NOTES, WEBEX, OUTDIAL, NOTIFICATION];
export const SUPERVISOR_AUTO_LOGIN_EVENT = "supervisor-auto-login";
export const BARGE_IN_EVENT_NAME = "BargedIn";
export const BARGE_IN_MONITORING_STATE = "BargeIn";
export const MICROSOFT_TEAMS = "Microsoft Teams";
export const USER_ROLES = {
    SUPERVISOR: "cjp.supervisor",
    CCS_ADMIN: "cjp.admin",
    PREMIUM_AGENT: "cjp.premium_agent",
    STANDARD_AGENT: "cjp.standard_agent",
    FULL_ADMIN: "id_full_admin",
    PROVISION_ADMIN: "atlas-portal.partner.provision_admin",
    READ_ONLY_ADMIN: "id_readonly_admin",
    PARTNER_ADMIN: "atlas-portal.partner.salesadmin",
    CISCO_TAC_ADMIN: "atlas-portal.partner.helpdesk"
};
export const SYSTEM_ROLES = {
    EXTERNAL_FULL_ADMIN: "EXTERNAL_FULL_ADMIN",
    EXTERNAL_READONLY_ADMIN: "EXTERNAL_READONLY_ADMIN",
    SUPERVISOR: "supervisor",
    AGENT: "agent",
    AGENT_AND_SUPERVISOR: "agent_supervisor"
};
export const ROUTE_NOT_FOUND_TYPE = {
    TASK_NOT_FOUND: "task-not-found",
    ROUTE_NOT_FOUND: "route-not-found"
};
export var MONITORING_TYPE;
(function (MONITORING_TYPE) {
    MONITORING_TYPE["MIDCALL"] = "midcall";
    MONITORING_TYPE["ADHOC"] = "adhoc";
    MONITORING_TYPE["CONTINUOUS"] = "continuous";
})(MONITORING_TYPE || (MONITORING_TYPE = {}));
export const MEDIA_TYPES = {
    MIDCALL_MONITORING: "midcall telephony",
    APPLE_BUSINESS_CHAT: "appleMessages",
    EMAIL: "email",
    SMS: "sms",
    OUTBOUND_AGENT_INITIATED_EMAIL: "outboundEmail",
    OUTBOUND_AGENT_INITIATED_SMS: "outboundSms"
};
export const OUTBOUND_TYPE = {
    OUTDIAL: "OUTDIAL",
    CALLBACK: "CALLBACK"
};
export const OUTBOUND = "OUTBOUND";
export const CALLBACK_RETRY_REASON_CODES = {
    NO_ANSWER_FROM_CUSTOMER: 1021,
    CUSTOMER_BUSY: 1022,
    CUSTOMER_UNAVAILABLE: 1023,
    AGENT_ENDS: 1024,
    NO_MATCHING_AGENT_CHANNEL_FOUND: 1025,
    INVALID_VTEAM: 1026,
    AGENT_CHANNEL_NOT_FOUND: 1027,
    REASON_CODE_NOT_AVAILABLE: 1028,
    AGENT_LEFT: 1029,
    CUSTOMER_LEFT: 1030,
    QUEUE_TIMEOUT: 1034
};
export const WEBEX_MINIMIZE_MODAL = "webex-minimize-modal";
export const WEBEX_SHOW_MODAL = "webex-show-modal";
export const DEFAULT_LOGIN_VOICE_OPTIONS = ["AGENT_DN", "EXTENSION"];
export const MicrophoneAccessStatus = {
    denied: "denied",
    prompt: "prompt",
    granted: "granted"
};
export const AGENT = "Agent";
export const CUSTOMER = "Customer";
export const SUPERVISOR = "Supervisor";
export const DEVICE_CHANGED = "device:changed";
export const CANPLAY_THROUGH = "canplaythrough";
export const WEB_CALLING_EVENTS = {
    AX_WC_REMOTE_MEDIA_UPDATE: "ax-web-call-remote-media-update",
    AX_WC_ANSWER: "ax-web-call-answer",
    AX_WC_DECLINE: "ax-web-call-decline",
    AX_WC_ESTABLISHED: "ax-web-call-established",
    AX_WC_MUTE_UNMUTE: "ax-web-call-mute-unmute",
    AX_WC_DISCONNECTED: "ax-web-call-disconnected",
    AX_WC_INCOMING: "ax-web-call-incoming",
    AX_WEB_CALL_AUTO_ANSWER: "ax-web-call-auto-answer"
};
export const DIGITAL_OUTBOUND_EVENTS = {
    AX_DIGITAL_OUTBOUND_AUTO_ANSWER: "ax-digital-outbound-auto-answer"
};
export const INTERACTION_EVENTS = {
    AX_INTERACTION_DELETE: "ax-interaction-delete"
};
export const WEB_RTC_STATUS = {
    WEB_RTC_CONNECTED: "webRtcConnected",
    WEB_RTC_REGISTERING: "webRtcRegistering",
    WEB_RTC_DISCONNECTED: "webRtcDisconnected"
};
export const PRESENCE_REQUEST_EVENTS = {
    AX_PRESENCE_REQUEST_SUCCESS: "presence-mercury-success",
    AX_PRESENCE_REQUEST_RETRY: "presence-mercury-retry",
    AX_PRESENCE_REQUEST_FAILURE: "presence-mercury-failure"
};
export const PRESENCE_STAT = {
    MERCURY: "mercury",
    SYNC: "sync",
    RETRYING: "retrying",
    SUCCESSFUL: "successful",
    FAILURE: "failure"
};
// Add all the new reason codes in this list for comparison, as well as in app.json for the messages to be localised
export const reasonCodesList = [
    1073,
    1072,
    1071,
    1070,
    1050,
    1005,
    1051,
    1010,
    1037,
    1038,
    1042,
    1043,
    1045,
    1046,
    1047,
    109,
    112,
    113,
    114,
    115,
    116,
    117,
    118,
    121,
    141,
    127,
    138,
    1021,
    1022,
    1023,
    1024,
    1029,
    1030,
    1017,
    1033,
    1028
];
export const TOGGLE_VOICE_INTERACTION_PANEL = "toggle-voice-interaction-panel";
export const FC_DESKTOP_VIEW = "FC-DESKTOP-VIEW";
export const fcDesktopView = "fcDesktopView";
export const AGENT_STATE_CHANGE_FAILED = "agent-state-change-failed";
export const APPLE_MESSAGE_DARK_SVG = "images/illustrations/apple-messages-dark.svg";
export const APPLE_MESSAGE_LIGHT_SVG = "images/illustrations/apple-messages-light.svg";
export const AGENT_STATE_CHANGEDBY_NAME = "agentStateChangedByName";
export const STATE_CHANGE_BANNER = "stageChangeBanner";
export const ALERT_BANNER_DETAILS = "alertBannerDetails";
export const mainICHeight = 81; // minimum height of main interaction control
export const consultICHeight = 81; // minimum height of consult interaction control
export const SUPERVISOR_EVENT = "SupervisorDesktopMessage";
export const PRESENCE_SYNC = "PRESENCE_SYNC";
export const PRESENCE_SYNC_TTL = 3600;
export const REVOKE_PRESENCE_TOKEN_FAILURE = "revoke-presence-token-failure";
export const AgentConsultEnded = "AgentConsultEnded";
export const AGENTX_DEFAULT_FOCUS = "agentx-default-focus";
export const PRESENCE_STATUS = {
    active: "active",
    call: "call",
    dnd: "dnd",
    meeting: "meeting",
    presenting: "presenting",
    clear: "clear",
    busy: "busy",
    outOfOffice: "ooo",
    quietHours: "quietHours",
    inACalendarMeeting: "inACalendarMeeting"
};
export const PRESENCE_MEETING_TYPE = {
    calendarItem: "calendarItem"
};
export const PRESENCE_STATUS_CALL_EVENTS = {
    CALL_CREATE: "ax-presence-call-create",
    CALL_DELETE: "ax-presence-call-delete"
};
export const COLLAB_TOOL = {
    WEBEX: "Webex",
    TEAMS: "Microsoft Teams"
};
export const WEBRTC_SHORTCUT_KEYS = [
    {
        widgetElement: "agentx-react-interaction-control",
        group: "app:keyboardShortcutKeys.interactionControl.componentName",
        action: "app:keyboardShortcutKeys.interactionControl.muteOrUnmuteWebCall",
        modifierKeys: "ctrlKey_shiftKey",
        key: "n",
        role: "agent",
        deviceType: "BROWSER"
    },
    {
        widgetElement: "agentx-react-interaction-control",
        group: "app:keyboardShortcutKeys.interactionControl.componentName",
        action: "app:keyboardShortcutKeys.interactionControl.openKeyboard",
        modifierKeys: "ctrlKey_altKey",
        key: "k",
        role: "agent",
        deviceType: "BROWSER"
    }
];
export var Key;
(function (Key) {
    Key["Enter"] = "Enter";
    Key["Space"] = "Space";
    Key["Tab"] = "Tab";
    Key["Escape"] = "Escape";
})(Key || (Key = {}));
export const AGENTX_MORE_ACTIONS_OPENED = "agentx-more-actions-opened";
export const AGENTX_GOTO_ANALYZER_COMPONENT = "agentx-wc-goto-analyzer";
export const ADD_FOCUS_AGENTX_GOTO_ANALYZER = "add-focus-goto-analyzer";
export const FIVE_HUNDRED_MS = 500;
export const AX_ANNOUNCEMENT_UPDATE = "ax-announcement-update";
export const POLITENESS_POLITE = "polite";
export const POLITENESS_ASSERTIVE = "assertive";
export const CONTACT_HISTORY_TAB_TITLE = "contact-history-tab-title";
export const TOGGLE_MAXIMIZED_EVENT = "toggle-maximized-event";
