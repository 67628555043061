var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UTILS_DYNAMIC } from "@/app/utils/UtilsDynamic";
import { t } from "@/mixins/i18nMixin";
import { ResizeMixin } from "@/mixins/ResizeMixin";
import { STORE } from "@/store";
import webexImg from "@img/teams-icon-color.svg";
import { customElement, html, internalProperty, LitElement, property, query, queryAll } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { ifDefined } from "lit-html/directives/if-defined";
import { handleShortcutKeyEvent, handleWebexModal, isSupervisor } from "../utils/Utils";
import { ACTION_ITEM_LI, LIST_ITEM, NOTES, NOTIFICATION, OUTDIAL, WEBEX } from "./constants";
//TODO ACTION_ITEM_LI multiple attributes are not accepting in li items
import style from "./HeaderActionItems.scss";
const fullScreenSize = 1280;
const margin = 16;
let HeaderWrapper = class HeaderWrapper extends ResizeMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.actionIcons = STORE.dynamic.actionIcons;
        this.isOutboundEnabledForTenant = STORE.agent.isOutboundEnabledForTenant;
        this.isOutboundEnabledForAgent = STORE.agent.isOutboundEnabledForAgent;
        this.getOutdialRegex = STORE.agent.getOutdialRegex;
        this.dnNumber = STORE.agent.dnNumber;
        this.selectedOutdialANI = STORE.agent.selectedOutdialANI;
        this.outDialEp = STORE.agent.outDialEp;
        this.isAdhocDialingEnabled = STORE.agent.isAdhocDialingEnabled;
        this.isActiveCall = STORE.agentContact.isActiveCall;
        this.isNotificationsEnabled = STORE.generalNotifications.isNotificationsEnabled;
        this.webexNotificationCount = STORE.generalNotifications.webexNotificationCount;
        this.notifications = STORE.generalNotifications.notifications;
        this.header = STORE.dynamic.header;
        this.outDialAni = STORE.agent.getOutDialAni;
        this.addressBook = STORE.agent.getAddressBook;
        this.hasWidgets = !!Object.keys(this.header || {}).length;
        this.webexEnabled = STORE.app.webexEnabled;
        this.cmsConfigFFEnabled = STORE.app.featureFlags.isCMSConfigMigrated;
        this.isOutdialSpecialCharsEnabled = STORE.app.featureFlags.isOutdialSpecialCharsEnabled;
        this.isDarkModeEnable = STORE.app.darkMode;
        this.isAddressBookInfiniteScrollEnabled = STORE.app.featureFlags.isAddressBookInfiniteScrollEnabled;
        this.isOutdialDisabledForExclusive = STORE.agentContact.shouldDisableOutdialForExclusive;
        this.showDynamicWidget = true;
        this.isCallInProgress = false;
        this.webRtcStatus = STORE.agent.webRtcStatus;
        this.actionItemMap = [];
        this.setDisplay = (data) => {
            data.forEach((val, index) => {
                if (val) {
                    this.actionBarItems[data.length - (index + 1)].classList.remove("hide");
                    this.overlayItems[data.length - (index + 1)].classList.add("hide");
                }
                else {
                    this.actionBarItems[data.length - (index + 1)].classList.add("hide");
                    this.overlayItems[data.length - (index + 1)].classList.remove("hide");
                }
            });
        };
    }
    static get styles() {
        return style;
    }
    connectedCallback() {
        super.connectedCallback();
        this.setDynamicWidgetVisibility();
        this.stopListen = handleShortcutKeyEvent();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.stopListen) {
            this.stopListen();
        }
    }
    firstUpdated(changedProperties) {
        const _super = Object.create(null, {
            firstUpdated: { get: () => super.firstUpdated }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.firstUpdated.call(this, changedProperties);
            /* Commenting below line as this is not doing any action */
            // await new Promise(resolve => setTimeout(resolve, 0));
            this.resizerEvent();
            this.switchColumnsToRows();
        });
    }
    handleResize() {
        this.resizerEvent();
    }
    resizerEvent() {
        var _a, _b;
        this.setDynamicWidgetVisibility();
        this.actionItemMap = [];
        const availableSlot = Math.floor((this.actionBar.offsetWidth - this.moreButton.offsetWidth - margin) /
            ((_b = (_a = this.actionBarItems[this.actionBarItems.length - 1]) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 0));
        for (let i = 0; i < this.actionBarItems.length; i++) {
            if (availableSlot > i) {
                this.actionItemMap.push(true);
            }
            else {
                this.actionItemMap.push(false);
            }
        }
        this.setDisplay(this.actionItemMap);
    }
    setDynamicWidgetVisibility() {
        if (window.innerWidth < fullScreenSize) {
            this.showDynamicWidget = true;
        }
        else {
            this.showDynamicWidget = false;
            setTimeout(() => {
                this.switchColumnsToRows();
            }, 100);
        }
    }
    switchColumnsToRows() {
        var _a, _b, _c, _d;
        const dynamicAreaInMenuContainer = (_b = (_a = this.dynamicAreaInMenu) === null || _a === void 0 ? void 0 : _a.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector(".container");
        const dynamicAreaOutMenuContainer = (_d = (_c = this.dynamicAreaOutMenu) === null || _c === void 0 ? void 0 : _c.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector(".container");
        const currentGridAreas = dynamicAreaOutMenuContainer === null || dynamicAreaOutMenuContainer === void 0 ? void 0 : dynamicAreaOutMenuContainer.style.gridTemplateAreas;
        if (dynamicAreaInMenuContainer) {
            dynamicAreaInMenuContainer.style.cssText = this.turnGridAround(currentGridAreas);
        }
    }
    turnGridAround(currentGridAreas) {
        if (currentGridAreas) {
            const areas = currentGridAreas.split(" ");
            // ** @yanaha: Sorry for this crutch, i had the hardest time trying to figure out template literals and such. I will be back.
            switch (areas.length) {
                case 1:
                    return `grid-template-areas: ${areas[0]}`;
                case 2:
                    return `grid-template-areas: ${areas[0]}" "${areas[1]}`;
                case 3:
                    return `grid-template-areas: ${areas[0]}" "${areas[1]}" "${areas[2]}`;
                case 4:
                    return `grid-template-areas: ${areas[0]}" "${areas[1]}" "${areas[2]}" "${areas[3]}`;
                case 5:
                    return `grid-template-areas: ${areas[0]}" "${areas[1]}" "${areas[2]}" "${areas[3]}" "${areas[4]}`;
                default:
                    return `grid-template-areas: ${currentGridAreas}`;
            }
        }
        return "";
    }
    renderActionElements(li) {
        var _a;
        const webexOpener = html `
      <span class="webex-notification-icon_wrap">
        <md-tooltip message=${t("app:common.webex")} placement="bottom">
          <md-button
            class="webex-opener"
            size="40"
            circle
            ariaLabel=${t("app:common.webexAriaLable", "Webex", { count: this.webexNotificationCount })}
            circle
            variant="secondary"
            @click=${() => {
            handleWebexModal();
        }}
          >
            <img class="webex-img" alt=${t("app:common.webex")} src=${webexImg} />
          </md-button>
        </md-tooltip>
        ${this.webexNotificationCount > 0
            ? html `
              <md-badge class="webex-notification-icon-badge" color="darkred" small>
                <span>${this.webexNotificationCount}</span>
              </md-badge>
            `
            : nothing}
      </span>
    `;
        const outDial = STORE.agent.orgId
            ? html `
          <agentx-react-out-dial
            .outDialAni="${this.outDialAni}"
            .addressBook="${this.addressBook}"
            isAddressBookInfiniteScrollEnabled=${this.isAddressBookInfiniteScrollEnabled}
            shouldDisableOutdialForExclusive="${this.isOutdialDisabledForExclusive}"
            isOutDialEnabledTenant="${this.isOutboundEnabledForTenant}"
            isOutDialEnabledAgent="${this.isOutboundEnabledForAgent}"
            phoneValidateRegex="${this.getOutdialRegex}"
            agentDn="${this.dnNumber}"
            outdialEntryPointId="${this.outDialEp}"
            isAdhocDialingEnabled="${this.isAdhocDialingEnabled}"
            isCallInProgress="${this.isActiveCall}"
            orgId="${STORE.agent.orgId}"
            .selectedANI="${this.selectedOutdialANI}"
            cmsConfigFFEnabled="${this.cmsConfigFFEnabled}"
            @ax-outdial-update-ani-list="${(e) => {
                STORE.agent.updateoutdialAniList(e.detail);
            }}"
            @ax-outdial-update-ani-list-failed="${() => {
                STORE.agent.updateoutdialAniListFailed();
            }}"
            @ax-outdial-fetch-addressbook-list-success=${(e) => {
                STORE.agent.updateAddressbookList(e.detail.data);
            }}
            @ax-outdial-fetch-addressbook-list-failure=${(e) => {
                STORE.agent.updateAddressbookListFailed(e.detail);
            }}
            @ax-outdial-ani=${(e) => {
                STORE.agent.updateSelectedOutdialANI(e.detail.data);
            }}
            isOutdialSpecialCharsEnabled="${this.isOutdialSpecialCharsEnabled}"
            isDarkModeEnable="${this.isDarkModeEnable}"
            webRtcStatus="${this.webRtcStatus}"
            isWebRTCEnabled="${STORE.app.featureFlags.isWebRTCEnabled}"
            deviceType="${ifDefined(STORE.agent.deviceType === null ? undefined : STORE.agent.deviceType)}"
          ></agentx-react-out-dial>
          <span class="action-label">${t("app:actionBar.outdial")}</span>
        `
            : nothing;
        const notificationMenu = html `
      ${this.isNotificationsEnabled
            ? html `
            <agentx-wc-menu-notification
              .notificationsService="${this.notifications}"
              @clear-notifications="${() => {
                this.dispatchEvent(new CustomEvent("clear-notifications", {
                    detail: {},
                    bubbles: true,
                    composed: true
                }));
            }}"
            ></agentx-wc-menu-notification>
            <span class="action-label">${t("app:actionBar.notifications")}</span>
          `
            : nothing}
    `;
        const notes = html `
      <agentx-wc-notes-header-widget .agentId=${STORE.agent.agentId}></agentx-wc-notes-header-widget>
      <span class="action-label">${t("app:notes.notes")}</span>
    `;
        return html `
      ${(_a = this.actionIcons) === null || _a === void 0 ? void 0 : _a.map((data) => {
            switch (data) {
                case NOTIFICATION: {
                    return html `
              ${li === "li"
                        ? html `
                    <li class="action-item-li ${classMap({ hidenotification: !this.isNotificationsEnabled })}">
                      ${notificationMenu}
                    </li>
                  `
                        : html `
                    <md-list-item
                      class="action-item-li ${classMap({ hidenotification: !this.isNotificationsEnabled })}"
                      slot="${LIST_ITEM}"
                      >${notificationMenu}</md-list-item
                    >
                  `}
            `;
                }
                case OUTDIAL: {
                    if (!isSupervisor()) {
                        return html `
                ${li === "li"
                            ? html `
                      <li class=${ACTION_ITEM_LI}>${outDial}</li>
                    `
                            : html `
                      <md-list-item class=${ACTION_ITEM_LI} slot=${LIST_ITEM}>${outDial}</md-list-item>
                    `}
              `;
                    }
                    else {
                        return nothing;
                    }
                }
                case WEBEX: {
                    return html `
              ${li === "li" && this.webexEnabled
                        ? html `
                    <li class=${ACTION_ITEM_LI}>${webexOpener}</li>
                  `
                        : this.webexEnabled
                            ? html `
                    <md-list-item class=${ACTION_ITEM_LI} slot=${LIST_ITEM}>${webexOpener}</md-list-item>
                  `
                            : nothing}
            `;
                }
                case NOTES: {
                    if (STORE.app.featureFlags.isDesktopNotesWidgetEnabled) {
                        return li === "li"
                            ? html `
                    <li class="action-item-li">${notes}</li>
                  `
                            : html `
                    <md-list-item class="action-item-li" slot="list-item">${notes}</md-list-item>
                  `;
                    }
                    else {
                        return html ``;
                    }
                }
                default: {
                    return ``;
                }
            }
        })};
    `;
    }
    dynamicWidgets(inMenu = false) {
        return html `
      ${this.header
            ? html `
            <uuip-dynamic-widgets
              .dataProvider=${UTILS_DYNAMIC.storeDataProvider}
              .page=${this.header}
              class=${classMap({
                "dynamic-widgets-in-menu": inMenu,
                "dynamic-widgets-out-menu": !inMenu
            })}
              width="100%"
              height=${inMenu ? "auto" : "64px"}
            ></uuip-dynamic-widgets>
          `
            : nothing}
    `;
    }
    render() {
        return html `
      <div class="action-bar-wrapper">
        <div
          class="configurable-area ${classMap({
            hide: this.hasWidgets ? this.showDynamicWidget : true
        })} "
        >
          ${this.dynamicWidgets()}
        </div>

        <div class="action-bar-holder">
          <div
            class="more ${classMap({
            hide: this.hasWidgets
                ? !this.showDynamicWidget && this.actionItemMap.indexOf(false) === -1
                : this.actionItemMap.indexOf(false) === -1
        })}"
          >
            <md-menu-overlay>
              <md-tooltip slot="menu-trigger" placement="bottom" message=${t("app:common.headerMore")}>
                <span>
                  <md-button color="color-none" slot="menu-trigger" size="40" circle aria-label="More">
                    <md-icon slot="icon" class="more-icon-button" name="more-adr_16"></md-icon>
                  </md-button>
                </span>
              </md-tooltip>
              <div class="overlay-wrapper-all">
                <div class="overlay-wrapper">
                  <md-list class="overlay-wrapper-list"> ${this.renderActionElements("md-list-item")} </md-list>
                </div>
                <div
                  class="${classMap({
            hide: this.hasWidgets ? !this.showDynamicWidget : true,
            separator: this.actionItemMap.indexOf(false) > -1
        })} dynamic-widgets"
                >
                  ${this.dynamicWidgets(true)}
                </div>
              </div>
            </md-menu-overlay>
          </div>
          <ul class="action-bar-ul">
            ${this.renderActionElements("li")}
          </ul>
        </div>
      </div>
    `;
    }
};
__decorate([
    property({ type: Array })
], HeaderWrapper.prototype, "actionIcons", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "isOutboundEnabledForTenant", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "isOutboundEnabledForAgent", void 0);
__decorate([
    property({ type: String })
], HeaderWrapper.prototype, "getOutdialRegex", void 0);
__decorate([
    property({ type: String })
], HeaderWrapper.prototype, "dnNumber", void 0);
__decorate([
    property({ type: Object })
], HeaderWrapper.prototype, "selectedOutdialANI", void 0);
__decorate([
    property({ type: String })
], HeaderWrapper.prototype, "outDialEp", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "isAdhocDialingEnabled", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "isActiveCall", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "isNotificationsEnabled", void 0);
__decorate([
    property({ type: Number })
], HeaderWrapper.prototype, "webexNotificationCount", void 0);
__decorate([
    property({ attribute: false })
], HeaderWrapper.prototype, "notifications", void 0);
__decorate([
    property({ attribute: false })
], HeaderWrapper.prototype, "header", void 0);
__decorate([
    property({ type: Object })
], HeaderWrapper.prototype, "outDialAni", void 0);
__decorate([
    property({ type: Object })
], HeaderWrapper.prototype, "addressBook", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "hasWidgets", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "webexEnabled", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "cmsConfigFFEnabled", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "isOutdialSpecialCharsEnabled", void 0);
__decorate([
    property({ attribute: false })
], HeaderWrapper.prototype, "isDarkModeEnable", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "isAddressBookInfiniteScrollEnabled", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "isOutdialDisabledForExclusive", void 0);
__decorate([
    property({
        type: Boolean
    })
], HeaderWrapper.prototype, "showDynamicWidget", void 0);
__decorate([
    property({ type: Boolean })
], HeaderWrapper.prototype, "isCallInProgress", void 0);
__decorate([
    property({ type: String })
], HeaderWrapper.prototype, "webRtcStatus", void 0);
__decorate([
    query(".action-bar-holder")
], HeaderWrapper.prototype, "actionBar", void 0);
__decorate([
    queryAll(".action-bar-ul .action-item-li")
], HeaderWrapper.prototype, "actionBarItems", void 0);
__decorate([
    queryAll(".overlay-wrapper .action-item-li")
], HeaderWrapper.prototype, "overlayItems", void 0);
__decorate([
    query(".dynamic-widgets-in-menu")
], HeaderWrapper.prototype, "dynamicAreaInMenu", void 0);
__decorate([
    query(".dynamic-widgets-out-menu")
], HeaderWrapper.prototype, "dynamicAreaOutMenu", void 0);
__decorate([
    query(".more")
], HeaderWrapper.prototype, "moreButton", void 0);
__decorate([
    internalProperty()
], HeaderWrapper.prototype, "actionItemMap", void 0);
HeaderWrapper = __decorate([
    customElement("header-action-items")
], HeaderWrapper);
export { HeaderWrapper };
