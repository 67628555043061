import { PRESENCE_STATUS, PRESENCE_STATUS_CALL_EVENTS, PRESENCE_SYNC, PRESENCE_SYNC_TTL } from "@/app/components/constants";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import { debounce, handleTelemetryOnPresenceChange, logger } from "../Utils";
import { displayBanner, getIdleCode } from "./UtilsSyncState";
export class StateSyncManager {
    constructor() {
        this.syncStatus = "unknown";
        this.handlePresenceStatusEvents = (e) => {
            logger.info("[PresenceStateSync]: Handle Presence Sync State update event");
            if ((STORE.agentContact.isActiveCall && e.data.status === PRESENCE_STATUS.call) ||
                this.syncStatus === e.data.status // handle the stuck state which keep pushing the same status.
            ) {
                logger.info("[PresenceStateSync]: Presence Sync StateChange suppressed");
                this.syncStatus = e.data.status;
                return;
            }
            this.syncStatus = e.data.status;
            const idleCode = getIdleCode(this.syncStatus, e.data.meetingType);
            logger.info("[PresenceStateSync]: idleCode:", idleCode);
            if (idleCode && idleCode !== "" && !this.isSyncStateSuppress(idleCode)) {
                const newState = idleCode === SERVICE.constants.AgentState.AvailableAuxCodeId
                    ? SERVICE.constants.AgentState.Available
                    : SERVICE.constants.AgentState.Idle;
                STORE.agent.updateStateByPresence(idleCode, newState);
                handleTelemetryOnPresenceChange();
                logger.info("[PresenceStateSync]: Presence Sync StateChange triggered");
            }
            else {
                logger.info("[PresenceStateSync]: Presence Sync StateChange suppressed");
            }
        };
        this.handlePreseceStateUpdate = (e) => {
            STORE.agent.updateLastStateChangeByPresence(e.data.lastStateChangeReason === PRESENCE_SYNC);
            if (e.data.lastStateChangeReason === PRESENCE_SYNC) {
                displayBanner(e.data.subStatus);
            }
        };
    }
    // returns true to suppress presence state sync to update.
    isSyncStateSuppress(idleCode) {
        if (STORE.agent.subStatus !== SERVICE.constants.AgentState.Available) {
            return !(STORE.agent.lastStateChangeByPresence && idleCode === SERVICE.constants.AgentState.AvailableAuxCodeId);
        }
        else if (STORE.agent.subStatus === SERVICE.constants.AgentState.Available &&
            idleCode !== SERVICE.constants.AgentState.AvailableAuxCodeId) {
            return false;
        }
        return true;
    }
    updateCallPresenceStatus() {
        logger.info(`[PresenceSync]: update presence on call connected with status:  ${PRESENCE_STATUS.call}`);
        SERVICE.webex.setStatus(PRESENCE_STATUS.call, PRESENCE_SYNC_TTL);
    }
    resetPresenceStatus() {
        logger.info("[PresenceSync]: update presence on call completion");
        SERVICE.webex.setStatus(PRESENCE_STATUS.call, 0);
    }
    static getCurrentPresenceStatus() {
        SERVICE.webex.getPresenceStatus(STORE.agent.agentId);
    }
    register() {
        logger.info("[PresenceStateSync]: Presence Sync State Registered to handlePresenceStatusEvents");
        SERVICE.webex.onNewPresenceStateSyncEvent.listen(debounce((event) => {
            var _a;
            logger.info("[PresenceStateSync]: event state update with status: ", event.data.status);
            ((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.meetingType) &&
                logger.info("[PresenceStateSync]: event state update with meetingType: ", event.data.meetingType);
            this.handlePresenceStatusEvents(event);
        }, 500));
        SERVICE.aqm.agent.eAgentStateChangeSuccess.listen(data => {
            this.handlePreseceStateUpdate(data);
        });
        window.addEventListener(PRESENCE_STATUS_CALL_EVENTS.CALL_CREATE, this.updateCallPresenceStatus);
        window.addEventListener(PRESENCE_STATUS_CALL_EVENTS.CALL_DELETE, this.resetPresenceStatus);
        StateSyncManager.getCurrentPresenceStatus();
        return () => {
            window.removeEventListener(PRESENCE_STATUS_CALL_EVENTS.CALL_CREATE, this.updateCallPresenceStatus);
            window.removeEventListener(PRESENCE_STATUS_CALL_EVENTS.CALL_DELETE, this.resetPresenceStatus);
        };
    }
}
