export const SUPERVISOR_DEFAULT_NAVIGATION_ITEMS = [
    {
        label: "app:navigation.task",
        icon: "view-list",
        iconType: "momentum",
        navigateTo: "task",
        align: "top"
    },
    {
        label: "app:navigation.help",
        icon: "help-circle",
        iconType: "momentum",
        navigateTo: "http://rtp-aspw-crs2/00000018WIA62583B50GYZ/output/output-ciscopdfflbp/cjp_b_30-olh-cisco-webex-contact-center-agent.pdf",
        align: "bottom"
    },
    {
        label: "app:navigation.shareFeedback",
        icon: "survey",
        iconType: "momentum",
        navigateTo: "https://exp.bz/WC-146636",
        align: "bottom"
    }
];
export const DEFAULT_NAVIGATION_ITEMS = [
    {
        label: "app:navigation.home",
        icon: "home",
        iconType: "momentum",
        navigateTo: "task",
        align: "top"
    },
    {
        label: "app:navigation.shareFeedback",
        icon: "survey",
        iconType: "momentum",
        navigateTo: "https://exp.bz/WC-146636",
        align: "bottom"
    },
    {
        label: "app:navigation.help",
        icon: "help-circle",
        iconType: "momentum",
        navigateTo: "http://rtp-aspw-crs2/00000018WIA62583B50GYZ/output/output-ciscopdfflbp/cjp_b_30-olh-cisco-webex-contact-center-agent.pdf",
        align: "bottom"
    }
];
