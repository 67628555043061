var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { customElement, html, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
export var CssDynamic;
(function (CssDynamic) {
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.vars = {};
        }
        /*eslint @typescript-eslint/no-empty-function: "off"*/
        updated() { }
        get strVars() {
            const vars = this.vars || {};
            return Object.keys(vars).reduce((acc, k) => {
                const v = vars[k];
                acc += `${k}: ${v};`;
                return acc;
            }, "");
        }
        createRenderRoot() {
            return this;
        }
        render() {
            return html `
        <style type="text/css">
          ${this.strVars.length
                ? html `
                * { ${unsafeHTML(this.strVars)} }
              `
                : nothing}
        </style>
      `;
        }
    };
    __decorate([
        property({ type: Object })
    ], Element.prototype, "vars", void 0);
    Element = __decorate([
        customElement("css-dynamic")
    ], Element);
    CssDynamic.Element = Element;
})(CssDynamic || (CssDynamic = {}));
